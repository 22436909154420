import config from 'src/config';
import Http from 'src/helpers/httpClient';
import { convertObjectToQueryString } from 'src/helpers/utils';

import { IGetOtpPayload, ICreateOfficialProfilePayload, FetchMyLearningPathRequestAction } from '@redux/user/interface';

const END_POINT = 'v1/users';
const END_POINT_V2 = 'v2/users';
const NOTIFICATION_END_POINT = 'v1/notifications';
const LEARNING_PATH_END_POINT_V1 = 'v1/learning-path';

export const USER_END_POINT = {
  USER_ME: `${END_POINT}/me`,
  USER_ME_CREDENTIALS: `${END_POINT}/me/credentials`,
  USER_ME_WISHLIST: `${END_POINT}/me/wishlist`,
  USER_ME_WISHLIST_COURSES: `${END_POINT}/me/wishlist/courses`,
  MY_NOTIFICATIONS: `${NOTIFICATION_END_POINT}/me`,
  USER_GENERATE_OTP: `${END_POINT}/generate-otp`,
  USER_VERIFY_OTP: `${END_POINT}/verify-otp`,
  MERGE_CPD_ACCOUNT: `${END_POINT}/merge-cpd-account`,
  MERGE_ACCOUNT: `${END_POINT}/merge-account`,
  CHECK_EMAIL_DUPLICATE: `${END_POINT}/check-email-duplicate`,
  CHECK_CITIZEN_ID_DUPLICATE: `${END_POINT}/check-citizen-id-duplicate`,
  UPDATE_IDS_EMAIL: `${END_POINT}/update-email`,
  CHECK_PASSWORD: `${END_POINT}/check-password`,
  IDS_USER: `${END_POINT}/ids-profile`,
  VALIDATE_EMAIL: `${END_POINT}/validate-email`,
  GET_COUNTRIES: `${END_POINT_V2}/countries`,
  USER_GENERATE_OTP_V2: `${END_POINT_V2}/generate-otp`,
  GET_OFFICIAL_PROFILE: `${END_POINT_V2}/official-profile`,
  CREATE_OFFICIAL_PROFILE: `${END_POINT_V2}/official-profile`,
  GET_MY_CERTIFICATES: `${END_POINT_V2}/certificates`,
  GET_MY_CERTIFICATES_BUNDLES: `${LEARNING_PATH_END_POINT_V1}/my-certificates`,
  GET_MY_LEARNING_PATH: `${LEARNING_PATH_END_POINT_V1}/me`,
};

const userAPI = {
  fetchProfile() {
    const apiUrl = `${config.API_DOMAIN}/${USER_END_POINT.USER_ME}`;
    return Http.get(apiUrl);
  },
  fetchIpAddress() {
    const apiUrl = `${config.API_DOMAIN}/${USER_END_POINT.USER_ME_CREDENTIALS}`;
    return Http.get(apiUrl);
  },
  fetchUserWishlist() {
    const apiUrl = `${config.API_DOMAIN}/${USER_END_POINT.USER_ME_WISHLIST}`;
    return Http.get(apiUrl);
  },
  fetchUserCourseWishlist(courseId: number) {
    const apiUrl = `${config.API_DOMAIN}/${USER_END_POINT.USER_ME_WISHLIST_COURSES}/${courseId}`;
    return Http.get(apiUrl);
  },
  createUserWishlist(data: { courseId: number }) {
    const apiUrl = `${config.API_DOMAIN}/${USER_END_POINT.USER_ME_WISHLIST}`;
    return Http.post(apiUrl, data);
  },
  deleteUserWishlist(courseId: number) {
    const apiUrl = `${config.API_DOMAIN}/${USER_END_POINT.USER_ME_WISHLIST}/${courseId}`;
    return Http.delete(apiUrl);
  },
  fetchMyNotifications(path: string) {
    const apiUrl = `${config.API_DOMAIN}/${path}`;
    return Http.get(apiUrl);
  },
  seenNotification(notificationId: number) {
    const apiUrl = `${config.API_DOMAIN}/${NOTIFICATION_END_POINT}/${notificationId}/seen`;
    return Http.patch(apiUrl, {});
  },
  readNotification(notificationId: number) {
    const apiUrl = `${config.API_DOMAIN}/${NOTIFICATION_END_POINT}/${notificationId}/read`;
    return Http.patch(apiUrl, {});
  },
  sendOTPVerificationEmail(email: string, refId?: number, otpType?: string) {
    const apiUrl = `${config.API_DOMAIN}/${USER_END_POINT.USER_GENERATE_OTP}`;
    return Http.post(apiUrl, { email, refId, otpType });
  },
  userOTPVerification(otpCode: string, refCode: string) {
    const apiUrl = `${config.API_DOMAIN}/${USER_END_POINT.USER_VERIFY_OTP}`;
    return Http.post(apiUrl, { otpCode, refCode });
  },
  mergeCpdAccount(citizenId: string, email: string) {
    const apiUrl = `${config.API_DOMAIN}/${USER_END_POINT.MERGE_CPD_ACCOUNT}`;
    return Http.post(apiUrl, { citizenId, email });
  },
  mergeAccount(citizenId: string, email: string) {
    const apiUrl = `${config.API_DOMAIN}/${USER_END_POINT.MERGE_ACCOUNT}`;
    return Http.post(apiUrl, { citizenId, email });
  },
  checkEmailDuplicate(email: string) {
    const queryUrl = convertObjectToQueryString({ email });
    const apiUrl = `${config.API_DOMAIN}/${USER_END_POINT.CHECK_EMAIL_DUPLICATE}?${queryUrl}`;
    return Http.get(apiUrl);
  },
  checkCitizenIdDuplicate(citizenId: string) {
    const queryUrl = convertObjectToQueryString({ citizenId });
    const apiUrl = `${config.API_DOMAIN}/${USER_END_POINT.CHECK_CITIZEN_ID_DUPLICATE}?${queryUrl}`;
    return Http.get(apiUrl);
  },
  updateIDSEmail(citizenId: string, email: string) {
    const apiUrl = `${config.API_DOMAIN}/${USER_END_POINT.UPDATE_IDS_EMAIL}`;
    return Http.post(apiUrl, { citizenId, email });
  },
  checkPassword(citizenId: string, password: string) {
    const apiUrl = `${config.API_DOMAIN}/${USER_END_POINT.CHECK_PASSWORD}`;
    return Http.post(apiUrl, { citizenId, password });
  },
  fetchIdsProfile() {
    const apiUrl = `${config.API_DOMAIN}/${USER_END_POINT.IDS_USER}`;
    return Http.get(apiUrl);
  },
  validateEmail() {
    const apiUrl = `${config.API_DOMAIN}/${USER_END_POINT.VALIDATE_EMAIL}`;
    return Http.post(apiUrl, null);
  },
  getCountries() {
    const apiUrl = `${config.API_DOMAIN}/${USER_END_POINT.GET_COUNTRIES}`;
    return Http.get(apiUrl);
  },
  getOfficialProfile(courseIdOrIdName: string) {
    const queryParams = new URLSearchParams();
    queryParams.append('courseIdOrIdName', courseIdOrIdName);

    const apiUrl = `${config.API_DOMAIN}/${USER_END_POINT.GET_OFFICIAL_PROFILE}?${queryParams}`;
    return Http.get(apiUrl);
  },
  createOfficialProfile(payload: ICreateOfficialProfilePayload) {
    const { courseIdOrIdName } = payload;
    let queryString = '';
    if (courseIdOrIdName) {
      queryString += `courseIdOrIdName=${encodeURIComponent(courseIdOrIdName)}`;
    }
    const apiUrl = `${config.API_DOMAIN}/${USER_END_POINT.CREATE_OFFICIAL_PROFILE}?${queryString}`;
    return Http.put(apiUrl, payload);
  },
  getOtp(payload: IGetOtpPayload) {
    const { email, refId, otpType } = payload;
    const apiUrl = `${config.API_DOMAIN}/${USER_END_POINT.USER_GENERATE_OTP_V2}`;
    return Http.post(apiUrl, { email, refId, otpType });
  },
  getMyCertificates() {
    const getCertificateUrl = new URL(USER_END_POINT.GET_MY_CERTIFICATES, config.API_DOMAIN);
    return Http.get(getCertificateUrl.toString());
  },
  getMyModuleCertificate(bundleId: string) {
    const getModuleCertificateUrl = `${config.API_DOMAIN}/${USER_END_POINT.GET_MY_CERTIFICATES_BUNDLES}/${bundleId}`;
    return Http.get(getModuleCertificateUrl.toString())
      .then((res) => res.data)
      .catch((error) => error);
  },
  getMyLearningPath(payload: FetchMyLearningPathRequestAction) {
    const keyword = payload?.keyword || '';
    const status = payload?.status || '';
    const apiUrl = `${config.API_DOMAIN}/${USER_END_POINT.GET_MY_LEARNING_PATH}?keyword=${keyword}&status=${status}`;
    return Http.get(apiUrl)
      .then((res) => res.data)
      .catch((error) => error);
  },
};

export default userAPI;
