import { Record } from 'immutable';

import { updateQuery } from '@helpers/utils';
import { CourseAPIQueryProps } from '@interfaces/course';
import courseActions from '@redux/course/actions';
import { CourseDetailReduxState } from '@redux/course/type/state';
import { defaultInitialState, failedState, loadingState, resetState, successStateWithData } from '@redux/state';

export type CourseStateType = {
  courseApiQueries: CourseAPIQueryProps;
};

const initState = new (Record<CourseDetailReduxState>({
  // TODO: old structure
  courseApiQueries: {},
  // new structure
  courseDetailSeoState: defaultInitialState(),
  courseDetailState: {
    ...defaultInitialState(),
    isLoading: true,
  },
  courseEnrollmentState: defaultInitialState(),
  courseDetailGlancesState: {
    ...defaultInitialState(),
    isLoading: true,
  },
  courseDetailCurriculumState: {
    ...defaultInitialState(),
    isLoading: true,
  },
  courseReviewListState: defaultInitialState(),
  courseReviewMeState: defaultInitialState(),
  courseReviewSummaryState: defaultInitialState(),
  createOrUpdateCourseReviewState: defaultInitialState(),
  courseDiscussionListState: {
    ...defaultInitialState(),
    isLoading: true,
  },
  createCourseDiscussionState: defaultInitialState(),
  addCourseDiscussionReplyState: defaultInitialState(),
  courseCertificateBundlesState: {
    ...defaultInitialState(),
    isLoading: true,
  },
  courseSeminarState: defaultInitialState(),
  courseSuggestionsState: defaultInitialState(),
}))();

export default function CourseReducer(state = initState, { type, payload, error }: any) {
  switch (type) {
    // TODO: old structure
    case courseActions.ADD_COURSE_API_QUERY:
      const query = { ...state.courseApiQueries, ...payload };
      if (Object.keys(query).length) {
        const convertQuery = Object.keys(query).reduce((acc: any, key: string) => {
          if (query[key].length) {
            acc[key] = query[key].join();
          } else {
            delete acc[key];
          }
          return acc;
        }, {});
        updateQuery(convertQuery);
      }
      const removeQueryEmpty = Object.keys(query).reduce((acc: any, key: string) => {
        if (query[key].length) {
          acc[key] = query[key];
        } else {
          delete acc[key];
        }
        return acc;
      }, {});
      return state.set('courseApiQueries', removeQueryEmpty);
    case courseActions.SET_COURSE_API_SORT_ORDER:
      updateQuery(payload);
      return state.set('courseApiQueries', payload);
    case courseActions.CLEAR_COURSE_API_QUERY: {
      let update: CourseAPIQueryProps = {};
      if (state.courseApiQueries.orderBy) {
        const sort = { sortBy: state.courseApiQueries.sortBy, orderBy: state.courseApiQueries.orderBy };
        update = { ...update, sortBy: sort.sortBy, orderBy: sort.orderBy };
      }
      return state.set('courseApiQueries', update);
    }
    case courseActions.CLEAR_COURSE_FILTER: {
      const clearCourseFilter = () => {
        let courseAPIQueryProps: CourseAPIQueryProps = {};
        if (state.courseApiQueries.q) {
          courseAPIQueryProps = { ...courseAPIQueryProps, q: state.courseApiQueries.q };
        }
        if (state.courseApiQueries.orderBy) {
          const sort = { sortBy: state.courseApiQueries.sortBy, orderBy: state.courseApiQueries.orderBy };
          courseAPIQueryProps = { ...courseAPIQueryProps, sortBy: sort.sortBy, orderBy: sort.orderBy };
        }
        return courseAPIQueryProps;
      };
      const reset = clearCourseFilter();
      updateQuery(reset);
      return state.set('courseApiQueries', reset);
    }

    // new structure
    case courseActions.FETCH_COURSE_DETAIL_SEO_REQUEST:
      return loadingState(state, 'courseDetailSeoState');
    case courseActions.FETCH_COURSE_DETAIL_SEO_SUCCESS:
      return successStateWithData(state, 'courseDetailSeoState', payload);
    case courseActions.FETCH_COURSE_DETAIL_SEO_ERROR:
      return failedState(state, 'courseDetailSeoState', error);
    case courseActions.FETCH_COURSE_DETAIL_SEO_RESET:
      return resetState(state, 'courseDetailSeoState');

    case courseActions.FETCH_COURSE_DETAIL_REQUEST:
      return loadingState(state, 'courseDetailState');
    case courseActions.FETCH_COURSE_DETAIL_SUCCESS:
      return successStateWithData(state, 'courseDetailState', payload);
    case courseActions.FETCH_COURSE_DETAIL_ERROR:
      return failedState(state, 'courseDetailState', error);
    case courseActions.FETCH_COURSE_DETAIL_RESET:
      return resetState(state, 'courseDetailState');

    case courseActions.FETCH_COURSE_ENROLLMENT_REQUEST:
      return loadingState(state, 'courseEnrollmentState');
    case courseActions.FETCH_COURSE_ENROLLMENT_SUCCESS:
      return successStateWithData(state, 'courseEnrollmentState', payload);
    case courseActions.FETCH_COURSE_ENROLLMENT_ERROR:
      return failedState(state, 'courseEnrollmentState', error);
    case courseActions.FETCH_COURSE_ENROLLMENT_RESET:
      return resetState(state, 'courseEnrollmentState');

    case courseActions.FETCH_COURSE_DETAIL_GLANCES_REQUEST:
      return loadingState(state, 'courseDetailGlancesState');
    case courseActions.FETCH_COURSE_DETAIL_GLANCES_SUCCESS:
      return successStateWithData(state, 'courseDetailGlancesState', payload);
    case courseActions.FETCH_COURSE_DETAIL_GLANCES_ERROR:
      return failedState(state, 'courseDetailGlancesState', error);
    case courseActions.FETCH_COURSE_DETAIL_GLANCES_RESET:
      return resetState(state, 'courseDetailGlancesState');

    case courseActions.FETCH_COURSE_DETAIL_CURRICULUM_REQUEST:
      return loadingState(state, 'courseDetailCurriculumState');
    case courseActions.FETCH_COURSE_DETAIL_CURRICULUM_SUCCESS:
      return successStateWithData(state, 'courseDetailCurriculumState', payload);
    case courseActions.FETCH_COURSE_DETAIL_CURRICULUM_ERROR:
      return failedState(state, 'courseDetailCurriculumState', error);
    case courseActions.FETCH_COURSE_DETAIL_CURRICULUM_RESET:
      return resetState(state, 'courseDetailCurriculumState');

    case courseActions.FETCH_COURSE_DETAIL_REVIEW_LIST_REQUEST:
      return loadingState(state, 'courseReviewListState');
    case courseActions.FETCH_COURSE_DETAIL_REVIEW_LIST_SUCCESS:
      return successStateWithData(state, 'courseReviewListState', payload);
    case courseActions.FETCH_COURSE_DETAIL_REVIEW_LIST_ERROR:
      return failedState(state, 'courseReviewListState', error);
    case courseActions.FETCH_COURSE_DETAIL_REVIEW_LIST_RESET:
      return resetState(state, 'courseReviewListState');

    case courseActions.FETCH_COURSE_DETAIL_REVIEW_ME_REQUEST:
      return loadingState(state, 'courseReviewMeState');
    case courseActions.FETCH_COURSE_DETAIL_REVIEW_ME_SUCCESS:
      return successStateWithData(state, 'courseReviewMeState', payload);
    case courseActions.FETCH_COURSE_DETAIL_REVIEW_ME_ERROR:
      return failedState(state, 'courseReviewMeState', error);
    case courseActions.FETCH_COURSE_DETAIL_REVIEW_ME_RESET:
      return resetState(state, 'courseReviewMeState');

    case courseActions.FETCH_COURSE_DETAIL_REVIEW_SUMMARY_REQUEST:
      return loadingState(state, 'courseReviewSummaryState');
    case courseActions.FETCH_COURSE_DETAIL_REVIEW_SUMMARY_SUCCESS:
      return successStateWithData(state, 'courseReviewSummaryState', payload);
    case courseActions.FETCH_COURSE_DETAIL_REVIEW_SUMMARY_ERROR:
      return failedState(state, 'courseReviewSummaryState', error);
    case courseActions.FETCH_COURSE_DETAIL_REVIEW_SUMMARY_RESET:
      return resetState(state, 'courseReviewSummaryState');

    case courseActions.CREATE_OR_UPDATE_COURSE_DETAIL_REVIEW_REQUEST:
      return loadingState(state, 'createOrUpdateCourseReviewState');
    case courseActions.CREATE_OR_UPDATE_COURSE_DETAIL_REVIEW_SUCCESS:
      return successStateWithData(state, 'createOrUpdateCourseReviewState', payload);
    case courseActions.CREATE_OR_UPDATE_COURSE_DETAIL_REVIEW_ERROR:
      return failedState(state, 'createOrUpdateCourseReviewState', error);
    case courseActions.CREATE_OR_UPDATE_COURSE_DETAIL_REVIEW_RESET:
      return resetState(state, 'createOrUpdateCourseReviewState');

    case courseActions.FETCH_COURSE_DETAIL_DISCUSSION_LIST_REQUEST:
      return loadingState(state, 'courseDiscussionListState');
    case courseActions.FETCH_COURSE_DETAIL_DISCUSSION_LIST_SUCCESS:
      return successStateWithData(state, 'courseDiscussionListState', payload);
    case courseActions.FETCH_COURSE_DETAIL_DISCUSSION_LIST_ERROR:
      return failedState(state, 'courseDiscussionListState', error);
    case courseActions.FETCH_COURSE_DETAIL_DISCUSSION_LIST_RESET:
      return resetState(state, 'courseDiscussionListState');

    case courseActions.CREATE_COURSE_DISCUSSION_REQUEST:
      return loadingState(state, 'createCourseDiscussionState');
    case courseActions.CREATE_COURSE_DISCUSSION_SUCCESS:
      return successStateWithData(state, 'createCourseDiscussionState', payload);
    case courseActions.CREATE_COURSE_DISCUSSION_ERROR:
      return failedState(state, 'createCourseDiscussionState', error);
    case courseActions.CREATE_COURSE_DISCUSSION_RESET:
      return resetState(state, 'createCourseDiscussionState');

    case courseActions.ADD_COURSE_DISCUSSION_REPLY_REQUEST:
      return loadingState(state, 'addCourseDiscussionReplyState');
    case courseActions.ADD_COURSE_DISCUSSION_REPLY_SUCCESS:
      return successStateWithData(state, 'addCourseDiscussionReplyState', payload);
    case courseActions.ADD_COURSE_DISCUSSION_REPLY_ERROR:
      return failedState(state, 'addCourseDiscussionReplyState', error);
    case courseActions.ADD_COURSE_DISCUSSION_REPLY_RESET:
      return resetState(state, 'addCourseDiscussionReplyState');

    case courseActions.FETCH_COURSE_CERTIFICATE_BUNDLES_REQUEST:
      return loadingState(state, 'courseCertificateBundlesState');
    case courseActions.FETCH_COURSE_CERTIFICATE_BUNDLES_SUCCESS:
      return successStateWithData(state, 'courseCertificateBundlesState', payload);
    case courseActions.FETCH_COURSE_CERTIFICATE_BUNDLES_ERROR:
      return failedState(state, 'courseCertificateBundlesState', error);
    case courseActions.FETCH_COURSE_CERTIFICATE_BUNDLES_RESET:
      return resetState(state, 'courseCertificateBundlesState');
    case courseActions.FETCH_COURSE_SEMINAR_REQUEST:
      return loadingState(state, 'courseSeminarState');
    case courseActions.FETCH_COURSE_SEMINAR_SUCCESS:
      return successStateWithData(state, 'courseSeminarState', payload);
    case courseActions.FETCH_COURSE_SEMINAR_ERROR:
      return failedState(state, 'courseSeminarState', error);
    case courseActions.FETCH_COURSE_SEMINAR_RESET:
      return resetState(state, 'courseSeminarState');

    case courseActions.FETCH_COURSE_SUGGESTIONS_REQUEST:
      return loadingState(state, 'courseSuggestionsState');
    case courseActions.FETCH_COURSE_SUGGESTIONS_SUCCESS:
      return successStateWithData(state, 'courseSuggestionsState', payload);
    case courseActions.FETCH_COURSE_SUGGESTIONS_ERROR:
      return failedState(state, 'courseSuggestionsState', error);
    case courseActions.FETCH_COURSE_SUGGESTIONS_RESET:
      return resetState(state, 'courseSuggestionsState');

    default:
      return state;
  }
}
