import { ProductAffiliation } from '@common/type/ProductType';

import { ACADEMIC_TYPE, EnrollmentStatus } from '../helpers/constants';

import { ICampaign } from './campaign';
import { ICategory } from './category';
import { IEnrollment } from './enrollment';
import { IProduct } from './product';

import { IArticle, IInteractiveContentSubmissions, InteractionType, IQuiz } from '@interfaces/learning';

export interface IInstructorsInfo {
  id: number;
  email: string;
  firstname: string;
  lastname: string;
  official_firstname: string;
  official_lastname: string;
  official_title: string;
  salute: string;
  citizen_id: string;
  biology: string;
  is_email_valid: boolean;
  last_email_validated_at?: Date;
  email_validated_result_code?: any;
  is_user_test: boolean;
  created_at: Date;
  updated_at: Date;
  deleted_at?: any;
  session: string;
  password_enabled: boolean;
  image_file_name: string;
  image_partition: string;
  image_content_type: string;
  image_file_size?: number;
  image_updated_at?: Date;
  gender: any;
  birthday?: Date;
  reset_password_code?: any;
  signup_validation: string;
  highlight_desc: string;
  email_validated: boolean;
  last_visit_url: string;
  phone_country_id?: number;
  phone: string;
  provider: string;
  uid: string;
  disable: boolean;
  slug: string;
  biography: string;
  fb_share_title: string;
  fb_share_description: string;
  cover_photo_file_name?: any;
  cover_photo_content_type?: any;
  cover_photo_file_size?: any;
  cover_photo_updated_at?: any;
  fb_share_image_file_name?: any;
  fb_share_image_content_type?: any;
  fb_share_image_file_size?: any;
  fb_share_image_updated_at?: any;
  access_token?: any;
  session_api?: any;
  phone_validated?: any;
  session_api_time?: any;
  linked_token?: any;
  course_bundle_ids?: any;
  is_individual: boolean;
  state?: any;
  company_id?: any;
  company_status?: any;
  signature_file_name?: any;
  signature_content_type?: any;
  signature_file_size?: any;
  signature_updated_at?: any;
  line_notify_token: string;
  badge_tag: string;
  badge_icon: string;
  tu_gen_next_id?: any;
  signature_image_file_name: string;
  signature_image_content_type: string;
  signature_image_file_size?: number;
  signature_image_updated_at?: Date;
  image_url: string;
  user_id: string;
  security_stamp: string;
  address: string;
  tax_id: string;
  tax_name: string;
  flag_vat?: boolean;
  account_name: string;
  bank: string;
  branch: string;
  account_number: string;
  financial_type?: any;
  official_firstname_en?: any;
  official_lastname_en?: any;
  average_star?: number;
  total_reviews?: number;
  total_courses?: number;
}

export interface ICoursesGlance {
  id: number;
  name: string;
  image: string;
  sort_index?: any;
  created_at: Date;
  updated_at: Date;
}

export interface IWishlist {
  id?: number;
  user_id: number;
  course_id: number;
  created_at: Date;
  updated_at: Date;
}
export interface Attachment {
  id: number;
  course_item_id: number;
  position: number;
  created_at: Date;
  updated_at: Date;
  attachment_file_name: string;
  attachment_content_type: string;
  attachment_file_size: number;
  attachment_updated_at: Date;
}

export type IChapters = {
  id: number;
  name: string;
  created_at: Date;
  updated_at: Date;
  description: string;
  course_id: number;
  video_file_name: string;
  video_content_type: string;
  video_file_size: number;
  video_updated_at: Date;
  duration: number;
  video_version: number;
  transcode_status: string;
  transcode_job_id: string;
  video_playlists: string;
  deleted_at: Date;
  is_free: boolean;
  publish_at: Date;
};
export type ISurveys = {
  id: number;
  name: string;
  content_html: string;
  created_at: Date;
  updated_at: Date;
  deleted_at: Date;
};
export type IArticles = {
  id: number;
  name: string;
  description: string;
  duration: number;
  created_at: Date;
  updated_at: Date;
};
export interface ICourseItem {
  id: number;
  course_id: number;
  part_id: number;
  content_type: string;
  position: number;
  chapter_id?: number;
  quiz_id?: number;
  article_id?: number;
  survey_id?: number;
  infographic_id?: number;
  created_at: Date;
  updated_at: Date;
  deleted_at?: Date;
  is_enable?: boolean;
  b2b_enabled: boolean;
  is_preview: boolean;
  is_compulsory_above: boolean;
  is_compulsory_below: boolean;
  is_criteria?: boolean;
  criteria_score?: number;
  chapter: IChapters;
  survey: ISurveys;
  articles: IArticles;
  attachment: Attachment[];
  quizzes: IQuizzes;
  videoURL?: string;
}

export interface IMyCourseItem {
  id: number;
  academic_type: ACADEMIC_TYPE;
  expiry_time?: Date;
  exam_expiry_time?: Date;
  learning_status: 'InProgress' | 'Done' | 'Failed' | 'WaitForGrade' | 'Passed';
  learning_progress: number;
  has_certificate: boolean;
  has_certificate_bundle: boolean;
  courses: {
    name: string;
    have_certificate?: boolean;
    highlight_medium_image_path?: string;
    id_name?: string;
    productName: string;
  };
  evaluation_results: {
    status?: string;
    is_pass?: boolean;
  };
  display_certificate?: boolean;
  completed_date?: Date;
  certificate?: string;
  isFinishedAllAssignment: boolean;
  canRegrade: boolean;
  webSalePrices: {
    audit: number;
    credit: number;
    upgrade: number;
    regular: number;
  };
}

export interface ICourseAcademicInfo {
  id: number;
  course_id: number;
  primary_code: string;
  code: string;
  certificate_name: string;
  audit_price: number;
  audit_full_price: number;
  credit_price: number;
  credit_full_price: number;
  upgrade_price: number;
  upgrade_full_price: number;
  credit_qty: number;
  expired_day: number;
  created_at: Date;
  updated_at: Date;
  tu_course_update_at: Date;
}

export interface ICustomGlance {
  id: number;
  name: string;
  icon: string;
  course_id: number;
  position?: any;
  created_at: Date;
  updated_at: Date;
}

export interface IQuizzes {
  id: number;
  name: string;
  description: string;
  enabled: boolean;
  show_answer: boolean;
  retest: boolean;
  compulsory: boolean;
  deleted_at?: Date;
  created_at: Date;
  updated_at: Date;
  published_at: Date;
  total_score: number;
  percent_ratio?: number;
  validate_question_set_score: boolean;
  time_limit: number;
  time_limit_day: number;
  retest_times: number;
  enable_timer?: boolean;
  quiz_type: string;
  test_type: string;
}

export interface IPart {
  id: number;
  name: string;
  description: string;
  position: number;
  course_items: ICourseItem[];
  part_type: string;
  deleted_at?: Date;
  created_at: Date;
  updated_at: Date;
  published_at: Date;
}

export interface Category {
  name: string;
}

export interface CoursesCategory {
  id: number;
  course_id: number;
  category_id: number;
  is_main: boolean;
  sub_category_id: number;
  category?: Category;
  sub_category?: Category;
}

export interface ICourse {
  imageCDNHost: string;
  id: number;
  name: string;
  id_name: string;
  duration: number;
  video_amount: number;
  full_price: number;
  price: number;
  published_at: Date;
  preview_portrait_file_name?: any;
  preorder_end?: Date;
  is_academic: boolean;
  have_certificate?: boolean;
  custom_glances: ICustomGlance[];
  highlight_img_file_name: string;
  highlight_image: string;
  highlight_medium_image: string;
  highlight_image_original: string;
  highlight_image_webp: string;
  highlight_image_src_set: string;
  highlight_image_src_set_webp: string;
  instructors_info: IInstructorsInfo[];
  instructors: IInstructorsInfo[];
  preorder?: boolean;
  preorder_date?: string;
  courses_glances: ICoursesGlance[];
  wishlist?: IWishlist;
  enrollment?: IEnrollment;
  course_items: ICourseItem[];
  course_academic_infos: ICourseAcademicInfo;
  countdown_day?: number;
  attachment_exits?: boolean;
  degree_courses: IDegreeCourse[];
  amount_day_expired?: number;
  enable?: boolean;
  bundle_items?: ICourse[];
  regulator: CPDRegulatorTypeEnum;
  licenseType: string;
  applicantType: string;
  applicantTypeTH: string;
  profession_type: string;
  available_days_to_learn?: number;
  trainingCenter?: string;
  licenseTypes: CPDLicenseTypeEnum[];
  licenseRenewal?: number;
  isDeduct?: boolean;
  tw_content: string;
  mail_content: string;
  mail_use_template: number;
  mail_subject: string;
  cpd_enabled: boolean;
  description?: string;
  default_glances?: IDefault_glances;
  parts?: IPart[];
  course_cpd?: ICourseCpd;
  contentId: string;
  product: IProduct;
  is_require_full_official_profile: boolean;
  videoPreviewURL?: string;
  isEnrolled?: boolean;
  criteria_learn_percent?: number;
  isCpdBundle?: boolean;
  bundleCollectHours?: number;
  isCpd?: boolean;
  isLearningPageAllowed?: boolean;
  seoDescription: string;
  courses_categories?: CoursesCategory[];
  productAffiliation: ProductAffiliation;
  isCertificateBundle: boolean;
}

export enum CommissionRateUnitEnum {
  Percent = 'percent',
  FixAmount = 'fix_amount',
}

export enum ProductStatusEnum {
  Approved = 'approved',
  Creating = 'creating',
  Reviewing = 'reviewing',
}

export enum PriceUnitEnum {
  THB = 'thb',
  Credit = 'credit',
}

export interface ICourseCpd {
  id: number;
  course_id: number;
  collect_hours: number;
}

export interface IDefault_glances {
  total_videos: number;
  total_hours: number;
  available_days_to_learn: number;
  collect_hours: number;
  total_downloaded_files: number;
  total_quizzes: number;
  total_articles: number;
}

export interface IDegreeCourse {
  id: number;
  degree_id: number;
  course_id: number;
  degree_course_type: string;
  min_grade?: any;
  coming_soon: boolean;
  position: number;
  created_at: Date;
  updated_at: Date;
}
export interface ISection {
  id: number;
  name: string;
  s_type: SectionType;
  url_name: string;
  enable: boolean;
  position: number;
  created_at: Date;
  updated_at: Date;
  courses: ICourse[];
  categories: ICategory[];
  campaigns: ICampaign[];
  subcategories: ICategory[];
}

export interface ISectionCoursesPagination extends ICoursesPagination {
  title: string;
  type: string;
}

export interface ICoursesPagination {
  count: number;
  items: ICourse[];
}

export enum SectionType {
  Latest = 'latest',
  Popular = 'popular',
  FreePopular = 'free-popular',
  Recommend = 'recommend',
  Bundle = 'bundle',
  Custom = 'custom',
  Tuxsa = 'tuxsa',
  LearningPath = 'learning-path',
  PopularCategory = 'popular-category',
  Campaign = 'campaign',
  PopularInstructor = 'popular-instructor',
  Subcategory = 'subcategory',
}

export enum CourseFiltersKey {
  CATEGORY = 'categoryId',
  SUBCATEGORY = 'subcategoryId',
  PRICE = 'priceRange',
  CERTIFICATE = 'haveCertificate',
  COURSE_INCLUDE = 'courseInclude',
  DURATION = 'duration',
}

export enum CourseInclude {
  Attachment = 'attachment',
  Quiz = 'quiz',
  Certificate = 'certificate',
  LearningPath = 'learningPath',
}

export type CourseFilterObjectProps = {
  key: CourseFiltersKey;
  name: string;
  list: Array<CourseFilterObjectItemProps>;
  isShowMore: boolean;
};

export type CourseFilterObjectItemProps = {
  name: string;
  value: string | boolean;
  isChecked?: boolean;
};

export type CourseAPIQueryProps = {
  categoryId?: string[];
  subcategoryId?: string[];
  priceRange?: string[];
  haveCertificate?: string[];
  courseInclude?: string[];
  duration?: string;
  sortBy?: string;
  orderBy?: string;
  q?: string;
};

export enum CourseFilterPageKey {
  AllCourses = 'allCourses',
  SearchCourses = 'searchCourses',
}

export enum CourseCPDType {
  OIC = 'attachment',
  Quiz = 'quiz',
  Certificate = 'certificate',
}

export enum CPDLicenseTypeEnum {
  LIFE = 'LIFE',
  NON_LIFE = 'NON-LIFE',
  INVESTMENT = 'INVESTMENT',
  BOTH = 'BOTH',
}

export enum CPDRegulatorTypeEnum {
  OIC = 'OIC',
  TSI = 'TSI',
  ALL = 'all',
}

export enum ApplicantTypeEnum {
  BROKER = 'BROKER',
  AGENT = 'AGENT',
  ADVISOR = 'ADVISOR',
  ANALYST = 'ANALYST',
  PLANNER = 'PLANNER',
}

export enum FilterLicenseRenewalEnums {
  RequestToThirdLicense = '0to3',
  FourthPlusRenewal = '4',
  NotSet = '99',
}

export enum FilterLicenseTypeEnums {
  LIFE = 'life',
  NON_LIFE = 'non_life',
}

export enum CPDEnrollmentStatusEnum {
  Available = 'available',
  CourseUnMatched = 'course_unmatched',
  Enrolled = 'enrolled',
  RegulatorUnChecked = 'regulator_unchecked',
  LicenseSuspended = 'license_suspended',
  RoundOutdated = 'round_outdated',
}

export interface ICPDEnrollmentParams {
  id: number;
  preEnrollmentId: number;
  orderDeliveryItemId: number;
  courseId?: number;
  paymentId?: number;
  status?: string;
  userId?: string;
  roundId?: string;
  roundStartDate?: string;
  roundEndDate?: string;
  createdAt?: Date;
  updatedAt?: Date;
}

export interface ICPDCourseEnrollment {
  citizenId: string | null;
  email: string | null;
  productSKUId: number | null;
  profile?: ICPDProfile;
  licenses: ICPDLicense[] | [];
  status: CPDEnrollmentStatusEnum;
  isRequestForLicense: boolean;
  roundId?: string;
  availableLicenses?: ICPDLicense[];
  isCpdCourseBundle: boolean;
  preEnrollmentDetail: ICPDEnrollmentParams | null;
  canEnrollToCourse: boolean;
  enrollStatus: EnrollmentStatus;
}

export interface ICPDProfile {
  prefix: string;
  firstname: string;
  lastname: string;
  mobile: string | null;
}

export interface ILicenseType {
  regulator: string;
  applicantType: string;
  type: string;
  name: string;
  value: string;
}
export interface ICPDLicense {
  regulator: CPDRegulatorTypeEnum;
  licenseNo: string;
  createdDate: Date;
  expiredDate: Date;
  licenseType: CPDLicenseTypeEnum;
  isExpired: boolean;
  applicantType: ApplicantTypeEnum;
}

export enum LicenseRenewalTypeEnum {
  RequestLicense = 0,
  FirstRenewal = 1,
  SecondRenewal = 2,
  ThirdRenewal = 3,
  FourthPlusRenewal = 4,
  NotSet = 99,
}

export interface ICPDCourse extends ICourse {
  id: number;
  regulator: CPDRegulatorTypeEnum;
  trainingCenter: string;
  licenseTypes: CPDLicenseTypeEnum[];
  applicantType: ApplicantTypeEnum;
  licenseRenewal: LicenseRenewalTypeEnum;
  isDeduct: boolean;
  expiryDay?: number;
  licenseNo: string;
  createdDate: Date | null;
  expiredDate: Date | null;
  licenseType: CPDLicenseTypeEnum;
  isRequestLicense: boolean;
}

export interface ICPDCourseRound {
  id: string;
  roundDate: Date;
  expiredDate: Date;
  firstRegistrationDate: Date;
  lastRegistrationDate: Date;
}

export interface ICourseInteractionSummary {
  courseName: string;
  totalEnrollment: number;
  totalInteractiveContent: number;
  interaction: {
    interactionType: InteractionType;
    count: number;
  }[];
}

export interface ICourseInteractionList {
  items: {
    id: number;
    course_item_id: number;
    chapter_id: number;
    name: string;
    is_enabled: boolean;
    interaction_type: InteractionType;
    course_items: ICourseItem;
    chapters: {
      name: string;
      interactive_enabled: boolean;
    };
    quiz: IQuiz & {
      interactive_content_submissions: IInteractiveContentSubmissions[];
    };
    article: IArticle & {
      interactive_content_submissions: IInteractiveContentSubmissions[];
    };
  }[];
  total: number;
}
