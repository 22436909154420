import {
  FetchModuleCertificateErrorAction,
  FetchModuleCertificateRequestAction,
  FetchModuleCertificateSuccessAction,
  FetchMyLearningPathErrorAction,
  FetchMyLearningPathRequestAction,
  FetchMyLearningPathSuccessAction,
  ICreateOfficialProfilePayload,
  IGetOtpPayload,
} from './interface';

const actions = {
  FETCH_IP_ADDRESS_REQUEST: 'learning/FETCH_IP_ADDRESS_REQUEST',
  FETCH_IP_ADDRESS_SUCCESS: 'learning/FETCH_IP_ADDRESS_SUCCESS',
  FETCH_IP_ADDRESS_ERROR: 'learning/FETCH_IP_ADDRESS_ERROR',
  CLEAR_IP_ADDRESS: 'learning/CLEAR_IP_ADDRESS',
  GET_COUNTRIES: 'user/GET_COUNTRIES',
  GET_COUNTRIES_SUCCESS: 'user/GET_COUNTRIES_SUCCESS',
  GET_COUNTRIES_ERROR: 'user/GET_COUNTRIES_ERROR',
  CLEAR_OTP: 'learning/CLEAR_OTP',
  GET_OTP: 'user/GET_OTP',
  GET_OTP_SUCCESS: 'user/GET_OTP_SUCCESS',
  GET_OTP_ERROR: 'user/GET_OTP_ERROR',
  GET_OFFICIAL_PROFILE: 'user/GET_OFFICIAL_PROFILE',
  GET_OFFICIAL_PROFILE_SUCCESS: 'user/GET_OFFICIAL_PROFILE_SUCCESS',
  GET_OFFICIAL_PROFILE_ERROR: 'user/GET_OFFICIAL_PROFILE_ERROR',
  CREATE_OFFICIAL_PROFILE: 'user/CREATE_OFFICIAL_PROFILE',
  CREATE_OFFICIAL_PROFILE_SUCCESS: 'user/CREATE_OFFICIAL_PROFILE_SUCCESS',
  CREATE_OFFICIAL_PROFILE_ERROR: 'user/CREATE_OFFICIAL_PROFILE_ERROR',
  GET_MY_CERTIFICATES: 'user/GET_MY_CERTIFICATES',
  GET_MY_CERTIFICATES_SUCCESS: 'user/GET_MY_CERTIFICATES_SUCCESS',
  GET_MY_CERTIFICATES_ERROR: 'user/GET_MY_CERTIFICATES_ERROR',
  fetch_ip_address: () => ({
    type: actions.FETCH_IP_ADDRESS_REQUEST,
  }),
  clear_ip_address: () => ({
    type: actions.CLEAR_IP_ADDRESS,
  }),
  get_official_profile: (courseIdOrIdName: string) => ({
    type: actions.GET_OFFICIAL_PROFILE,
    payload: { courseIdOrIdName },
  }),
  create_official_profile: (payload: ICreateOfficialProfilePayload) => ({
    type: actions.CREATE_OFFICIAL_PROFILE,
    payload,
  }),
  get_countries: () => ({
    type: actions.GET_COUNTRIES,
  }),
  clear_otp: () => ({
    type: actions.CLEAR_OTP,
  }),
  get_otp: (payload: IGetOtpPayload) => ({
    type: actions.GET_OTP,
    payload,
  }),
  get_my_certificates: () => ({
    type: actions.GET_MY_CERTIFICATES,
  }),

  FETCH_MODULE_CERTIFICATE_REQUEST: 'user/FETCH_MY_MODULE_CERTIFICATE_REQUEST',
  FETCH_MODULE_CERTIFICATE_SUCCESS: 'user/FETCH_MODULE_CERTIFICATE_SUCCESS',
  FETCH_MODULE_CERTIFICATE_ERROR: 'user/FETCH_MODULE_CERTIFICATE_ERROR',
  FETCH_MODULE_CERTIFICATE_RESET: 'user/FETCH_MODULE_CERTIFICATE_RESET',
  fetchModuleCertificateAction: {
    request: (payload: FetchModuleCertificateRequestAction) => ({
      type: actions.FETCH_MODULE_CERTIFICATE_REQUEST,
      payload,
    }),
    success: (payload: FetchModuleCertificateSuccessAction) => ({
      type: actions.FETCH_MODULE_CERTIFICATE_SUCCESS,
      payload,
    }),
    error: (payload: FetchModuleCertificateErrorAction) => ({
      type: actions.FETCH_MODULE_CERTIFICATE_ERROR,
      payload,
    }),
    reset: () => ({
      type: actions.FETCH_MODULE_CERTIFICATE_RESET,
    }),
  },

  FETCH_MY_LEARNING_PATH_REQUEST: 'user/FETCH_MY_LEARNING_PATH_REQUEST',
  FETCH_MY_LEARNING_PATH_SUCCESS: 'user/FETCH_MY_LEARNING_PATH_SUCCESS',
  FETCH_MY_LEARNING_PATH_ERROR: 'user/FETCH_MY_LEARNING_PATH_ERROR',
  FETCH_MY_LEARNING_PATH_RESET: 'user/FETCH_MY_LEARNING_PATH_RESET',
  fetchMyLearningPathAction: {
    request: (payload: FetchMyLearningPathRequestAction) => ({
      type: actions.FETCH_MY_LEARNING_PATH_REQUEST,
      payload,
    }),
    success: (payload: FetchMyLearningPathSuccessAction) => ({
      type: actions.FETCH_MY_LEARNING_PATH_SUCCESS,
      payload,
    }),
    error: (payload: FetchMyLearningPathErrorAction) => ({
      type: actions.FETCH_MY_LEARNING_PATH_ERROR,
      payload,
    }),
    reset: () => ({
      type: actions.FETCH_MY_LEARNING_PATH_RESET,
    }),
  },
};

export default actions;
