import { COURSE_END_POINT } from 'src/api/course';
import config from 'src/config';

import {
  FetchInstructorDropdownSuccessAction,
  FetchModuleCertificateAllCourseListRequestAction,
  FetchModuleCertificateCourseListRequest,
  FetchModuleCertificateCourseListResponse,
  FetchLearningPathListRequestAction,
  FetchLearningPathStudentListRequestAction,
  FetchLearningPathStudentListSuccessAction,
  HttpDeleteLearningPathApiResponse,
  PreviewCertificateRequest,
  SaveModuleCertificateCreateRequest,
  SearchCertificateBySlugRequest,
  UploadLearningPathImageRequest,
  DeleteLearningPathRequestAction,
  LearningPathStatusRequest,
  HttpFetchLearningPathListApiResponse,
  FetchLearningPathCourseBundleListRequestAction,
  FetchLearningPathCourseBundleListResponse,
  CreateLearningPathShareLogRequestAction,
  HttpFetchLearningPathDetailApiResponse,
} from './type';

import httpClient from '@helpers/httpClient';
import { toCamelCase } from '@helpers/utils';

export const moduleCertificateAPI = {
  fetchModuleCertificateCourseList: async (
    payload: FetchModuleCertificateCourseListRequest,
  ): Promise<FetchModuleCertificateCourseListResponse> => {
    const { categoryId, subCategoryId } = payload;
    let apiUrl = `${config.API_DOMAIN}/${COURSE_END_POINT.COURSES}?categoryId=${categoryId.join(',')}`;
    if (subCategoryId) {
      apiUrl = apiUrl.concat(`&subcategoryId=${subCategoryId.join(',')}`);
    }
    return httpClient
      .get(apiUrl.toString())
      .then((res) => res.data)
      .catch((error) => error);
  },
  fetchLearningPathList: async (
    payload: FetchLearningPathListRequestAction,
  ): Promise<HttpFetchLearningPathListApiResponse> => {
    const { searchingWord, slug, status, page, pageSize } = payload;

    const apiUrl = `${config.API_DOMAIN}/v1/learning-path/search`;
    const request = {
      ...(searchingWord && { searchingWord }),
      ...(slug && { slug }),
      ...(status && { status }),
      page,
      pageSize,
    };

    return httpClient
      .get(apiUrl, { params: request })
      .then((res) => res.data)
      .catch((error) => error);
  },
  fetchModuleCertificateAllCourseList: async (
    payload: FetchModuleCertificateAllCourseListRequestAction,
  ): Promise<FetchModuleCertificateCourseListResponse> => {
    const { searchingWord, instructor, mainCategory, subCategory, page, pageSize } = payload;
    const apiUrl = `${config.API_DOMAIN}/v2/courses/search/course/learning-path`;
    const request = {
      searchingWord,
      instructorId: instructor?.id,
      categoryId: mainCategory?.id,
      subcategoryId: subCategory?.id,
      page,
      pageSize,
    };
    return httpClient
      .get(apiUrl.toString(), { params: request })
      .then((res) => res.data)
      .catch((error) => error);
  },

  saveModuleCertificateCreate: async (payload: SaveModuleCertificateCreateRequest) => {
    const apiUrl = `${config.API_DOMAIN}/v1/learning-path`;
    if (payload.id) {
      return httpClient
        .put(apiUrl, payload)
        .then((res) => res.data)
        .catch((error) => error);
    }
    return httpClient
      .post(apiUrl, payload)
      .then((res) => res.data)
      .catch((error) => error);
  },
  fetchModuleCertificateInstructorDropdown: async (): Promise<FetchInstructorDropdownSuccessAction> => {
    const apiUrl = `${config.API_DOMAIN}/v1/learning-path/instructors-dropdown`;
    return httpClient
      .get(apiUrl.toString())
      .then((res) => res.data)
      .catch((error) => error);
  },
  fetchLearningPath: async (payload: { id: number }) => {
    const apiUrl = `${config.API_DOMAIN}/v1/learning-path/${payload.id}`;
    return httpClient
      .get(apiUrl)
      .then((res) => res.data)
      .catch((error) => error);
  },
  fetchLearningPathStudentList: async (
    payload: FetchLearningPathStudentListRequestAction,
  ): Promise<FetchLearningPathStudentListSuccessAction> => {
    const { searchingWord, id, email, status, page, pageSize } = payload;
    const queryParams = {
      page: String(page),
      pageSize: String(pageSize),
      learningPathId: id,
      ...(searchingWord && { searchingWord }),
      ...(email && { email }),
      ...(status && { status }),
    };
    const apiUrl = `${config.API_DOMAIN}/v1/learning-path/user/search`;

    return httpClient
      .get(apiUrl, { params: queryParams })
      .then((res) => res.data)
      .catch((error) => error);
  },
  fetchCertificateBySlugName: async (payload: SearchCertificateBySlugRequest) => {
    const apiUrl = `${config.API_DOMAIN}/v1/core-certificate/by-slug-name/${payload.slugName}`;
    return httpClient
      .get(apiUrl)
      .then((res) => res.data)
      .catch((error) => error);
  },
  uploadImage: async (payload: UploadLearningPathImageRequest) => {
    const apiUrl = `${config.API_DOMAIN}/v1/learning-path/upload-image?uploadPath=${payload.uploadLearningPathType}`;
    return httpClient
      .post(apiUrl, payload.formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => res.data)
      .catch((error) => error);
  },
  fetchPreviewCertificate: async (payload: PreviewCertificateRequest) => {
    const apiUrl = `${config.API_DOMAIN}/v1/core-certificate/preview`;
    return httpClient
      .post(apiUrl, payload)
      .then((res) => toCamelCase(res.data))
      .catch((error) => error);
  },
  deleteLearningPath: async (payload: DeleteLearningPathRequestAction): Promise<HttpDeleteLearningPathApiResponse> => {
    const apiUrl = `${config.API_DOMAIN}/v1/learning-path/${payload.id}`;
    return httpClient
      .delete(apiUrl.toString())
      .then((res) => res.data)
      .catch((error) => error);
  },
  updateLearningPathStatus: async (payload: LearningPathStatusRequest) => {
    const apiUrl = `${config.API_DOMAIN}/v1/learning-path/status`;
    return httpClient
      .put(apiUrl, payload)
      .then((res) => res.data)
      .catch((error) => error);
  },
  fetchLearningPathCourseBundleList: async (
    payload: FetchLearningPathCourseBundleListRequestAction,
  ): Promise<FetchLearningPathCourseBundleListResponse> => {
    const apiUrl = `${config.API_DOMAIN}/v1/learning-path/course-bundle`;

    return httpClient
      .get(apiUrl.toString(), { params: payload })
      .then((res) => res.data)
      .catch((error) => error);
  },

  getLearningPathDetail(learningPathId: string): Promise<HttpFetchLearningPathDetailApiResponse> {
    const apiUrl = `${config.API_DOMAIN}/v1/learning-path/my/detail/${learningPathId}`;
    return httpClient
      .get(apiUrl.toString())
      .then((res) => res.data)
      .catch((error) => error);
  },

  getLearningPathDetailSeo(learningPathId: string): Promise<HttpFetchLearningPathDetailApiResponse> {
    const apiUrl = `${config.API_DOMAIN}/v1/learning-path/seo/${learningPathId}`;
    return httpClient
      .get(apiUrl.toString())
      .then((res) => res.data)
      .catch((error) => error);
  },

  createLearningPathShareLog(payload: CreateLearningPathShareLogRequestAction) {
    const apiUrl = `${config.API_DOMAIN}/v1/learning-path/create-learning-path-share-log`;
    return httpClient
      .post(apiUrl, payload)
      .then((res) => res.data)
      .catch((error) => error);
  },
};
