import { Record } from 'immutable';

import learningPathActions from './action';
import { defaultModuleCertificateCreateErrorState, ManageLearningPathReduxState } from './type';

import {
  defaultInitialState,
  failedState,
  loadingState,
  resetState,
  successState,
  successStateWithData,
} from '@redux/state';

const initLearningPathState = new (Record<ManageLearningPathReduxState>({
  moduleCertificateCreate: defaultInitialState(),
  cachedModuleCertificateCreate: defaultInitialState(),
  slugDropdownOptions: defaultInitialState(),
  learningPathListState: defaultInitialState(),
  deleteLearningPathState: defaultInitialState(),
  mainCourseCategoryDropdownOptions: defaultInitialState(),
  subCourseCategoryDropdownOptions: defaultInitialState(),
  moduleCertificateCourseList: defaultInitialState(),
  moduleCertificateAllCourseList: defaultInitialState(),
  moduleCertificateCreateError: defaultInitialState(defaultModuleCertificateCreateErrorState),
  moduleCertificatePreview: defaultInitialState(),
  moduleCertificateCreateSaveDraft: defaultInitialState(),
  moduleCertificateSavePublish: defaultInitialState(),
  instructorDropdownOptions: defaultInitialState(),
  LearningPathStudentList: defaultInitialState(),
  moduleCertificateChangeStatus: defaultInitialState(),
  uploadLearningPathCardState: defaultInitialState(),
  uploadLearningPathBannerCoverState: defaultInitialState(),
  uploadLearningPathLineImageState: defaultInitialState(),
  uploadLearningPathFacebookImageState: defaultInitialState(),
  learningPathSectionItemList: defaultInitialState(),
  learningPathCourseBundleList: defaultInitialState(),
  learningPathDetailState: defaultInitialState(),
  learningPathDetailSeoState: defaultInitialState(),
  createLearningPathShareLogState: defaultInitialState(),
}))();

export default function learningPathReducer(state = initLearningPathState, { type, payload, error }: any) {
  switch (type) {
    case learningPathActions.LEARNING_PATH_CREATE_REQUEST:
      return loadingState(state, 'moduleCertificateCreate', { shouldResetData: true });

    case learningPathActions.LEARNING_PATH_CREATE_SUCCESS:
      return successStateWithData(state, 'moduleCertificateCreate', payload);

    case learningPathActions.LEARNING_PATH_CREATE_SET_DEFAULT:
      return successStateWithData(state, 'moduleCertificateCreate', payload);

    case learningPathActions.LEARNING_PATH_CREATE_UPDATE:
      return successStateWithData(state, 'moduleCertificateCreate', {
        ...state.moduleCertificateCreate.data,
        ...payload,
      });
    case learningPathActions.LEARNING_PATH_CREATE_RESET:
      return resetState(state, 'moduleCertificateCreate');

    case learningPathActions.CACHE_LEARNING_PATH_CREATE_UPDATE:
      return successStateWithData(state, 'cachedModuleCertificateCreate', payload);

    case learningPathActions.LEARNING_PATH_SLUG_DROPDOWN_REQUEST:
      return loadingState(state, 'slugDropdownOptions', { shouldResetData: true });

    case learningPathActions.LEARNING_PATH_SLUG_DROPDOWN_SUCCESS:
      return successStateWithData(state, 'slugDropdownOptions', payload);

    case learningPathActions.FETCH_LEARNING_PATH_LIST_REQUEST:
      return loadingState(state, 'learningPathListState');
    case learningPathActions.FETCH_LEARNING_PATH_LIST_SUCCESS:
      return successStateWithData(state, 'learningPathListState', payload);
    case learningPathActions.FETCH_LEARNING_PATH_LIST_ERROR:
      return failedState(state, 'learningPathListState', error);
    case learningPathActions.FETCH_LEARNING_PATH_LIST_RESET:
      return resetState(state, 'learningPathListState');

    case learningPathActions.LEARNING_PATH_MAIN_COURSE_CATEGORY_DROPDOWN_REQUEST:
      return loadingState(state, 'mainCourseCategoryDropdownOptions', { shouldResetData: true });
    case learningPathActions.LEARNING_PATH_MAIN_COURSE_CATEGORY_DROPDOWN_SUCCESS:
      return successStateWithData(state, 'mainCourseCategoryDropdownOptions', payload);

    case learningPathActions.LEARNING_PATH_SUB_COURSE_CATEGORY_DROPDOWN_SUCCESS:
      return successStateWithData(state, 'subCourseCategoryDropdownOptions', payload);

    case learningPathActions.FETCH_LEARNING_PATH_COURSE_LIST_REQUEST:
      return loadingState(state, 'moduleCertificateCourseList');
    case learningPathActions.FETCH_LEARNING_PATH_COURSE_LIST_SUCCESS:
      return successStateWithData(state, 'moduleCertificateCourseList', payload);
    case learningPathActions.FETCH_LEARNING_PATH_COURSE_LIST_ERROR:
      return failedState(state, 'moduleCertificateCourseList', error);
    case learningPathActions.FETCH_LEARNING_PATH_COURSE_LIST_RESET:
      return resetState(state, 'moduleCertificateCourseList');

    case learningPathActions.FETCH_LEARNING_PATH_ALL_COURSE_LIST_REQUEST:
      return loadingState(state, 'moduleCertificateAllCourseList');
    case learningPathActions.FETCH_LEARNING_PATH_ALL_COURSE_LIST_SUCCESS:
      return successStateWithData(state, 'moduleCertificateAllCourseList', payload);
    case learningPathActions.FETCH_LEARNING_PATH_ALL_COURSE_LIST_ERROR:
      return failedState(state, 'moduleCertificateAllCourseList', error);
    case learningPathActions.FETCH_LEARNING_PATH_ALL_COURSE_LIST_RESET:
      return resetState(state, 'moduleCertificateAllCourseList');

    case learningPathActions.LEARNING_PATH_CREATE_ERROR:
      return successStateWithData(state, 'moduleCertificateCreateError', payload);
    case learningPathActions.LEARNING_PATH_CREATE_ERROR_UPDATE:
      return successStateWithData(state, 'moduleCertificateCreateError', {
        ...state.moduleCertificateCreateError.data,
        ...payload,
      });
    case learningPathActions.LEARNING_PATH_CREATE_ERROR_RESET:
      return resetState(state, 'moduleCertificateCreateError');

    case learningPathActions.LEARNING_PATH_CREATE_PREVIEW_CERTIFICATE_REQUEST:
      return loadingState(state, 'moduleCertificatePreview', { shouldResetData: true });
    case learningPathActions.LEARNING_PATH_CREATE_PREVIEW_CERTIFICATE_SUCCESS:
      return successStateWithData(state, 'moduleCertificatePreview', payload);
    case learningPathActions.LEARNING_PATH_CREATE_PREVIEW_CERTIFICATE_ERROR:
      return failedState(state, 'moduleCertificatePreview', error);
    case learningPathActions.LEARNING_PATH_CREATE_PREVIEW_CERTIFICATE_RESET:
      return resetState(state, 'moduleCertificatePreview');

    case learningPathActions.LEARNING_PATH_CREATE_SAVE_DRAFT_REQUEST:
      return loadingState(state, 'moduleCertificateCreateSaveDraft', { shouldResetData: true });
    case learningPathActions.LEARNING_PATH_CREATE_SAVE_DRAFT_SUCCESS:
      return successStateWithData(state, 'moduleCertificateCreateSaveDraft', payload);
    case learningPathActions.LEARNING_PATH_CREATE_SAVE_DRAFT_ERROR:
      return failedState(state, 'moduleCertificateCreateSaveDraft', error);
    case learningPathActions.LEARNING_PATH_CREATE_SAVE_DRAFT_RESET:
      return resetState(state, 'moduleCertificateCreateSaveDraft');

    case learningPathActions.LEARNING_PATH_SAVE_PUBLISH_REQUEST:
      return loadingState(state, 'moduleCertificateSavePublish', { shouldResetData: true });
    case learningPathActions.LEARNING_PATH_SAVE_PUBLISH_SUCCESS:
      return successStateWithData(state, 'moduleCertificateSavePublish', payload);
    case learningPathActions.LEARNING_PATH_SAVE_PUBLISH_ERROR:
      return failedState(state, 'moduleCertificateSavePublish', error);
    case learningPathActions.LEARNING_PATH_SAVE_PUBLISH_RESET:
      return resetState(state, 'moduleCertificateSavePublish');

    case learningPathActions.LEARNING_PATH_INSTRUCTOR_DROPDOWN_REQUEST:
      return loadingState(state, 'instructorDropdownOptions', { shouldResetData: true });
    case learningPathActions.LEARNING_PATH_INSTRUCTOR_DROPDOWN_SUCCESS:
      return successStateWithData(state, 'instructorDropdownOptions', payload);

    case learningPathActions.FETCH_LEARNING_PATH_STUDENT_LIST_REQUEST:
      return loadingState(state, 'LearningPathStudentList');
    case learningPathActions.FETCH_LEARNING_PATH_STUDENT_LIST_SUCCESS:
      return successStateWithData(state, 'LearningPathStudentList', payload);
    case learningPathActions.FETCH_LEARNING_PATH_STUDENT_LIST_ERROR:
      return failedState(state, 'LearningPathStudentList', error);
    case learningPathActions.FETCH_LEARNING_PATH_STUDENT_LIST_RESET:
      return resetState(state, 'LearningPathStudentList');

    case learningPathActions.LEARNING_PATH_VIEW_DETAILS_CHANGE_STATUS_REQUEST:
      return loadingState(state, 'moduleCertificateChangeStatus');
    case learningPathActions.LEARNING_PATH_VIEW_DETAILS_CHANGE_STATUS_SUCCESS:
      return successState(state, 'moduleCertificateChangeStatus');
    case learningPathActions.LEARNING_PATH_VIEW_DETAILS_CHANGE_STATUS_ERROR:
      return failedState(state, 'moduleCertificateChangeStatus', error);
    case learningPathActions.LEARNING_PATH_VIEW_DETAILS_CHANGE_STATUS_RESET:
      return resetState(state, 'moduleCertificateChangeStatus');

    case learningPathActions.UPLOAD_LEARNING_PATH_CARD_REQUEST:
      return loadingState(state, 'uploadLearningPathCardState');
    case learningPathActions.UPLOAD_LEARNING_PATH_CARD_SUCCESS:
      return successStateWithData(state, 'uploadLearningPathCardState', payload);
    case learningPathActions.UPLOAD_LEARNING_PATH_CARD_ERROR:
      return failedState(state, 'uploadLearningPathCardState', error);

    case learningPathActions.UPLOAD_LEARNING_PATH_BANNER_COVER_REQUEST:
      return loadingState(state, 'uploadLearningPathBannerCoverState');
    case learningPathActions.UPLOAD_LEARNING_PATH_BANNER_COVER_SUCCESS:
      return successStateWithData(state, 'uploadLearningPathBannerCoverState', payload);
    case learningPathActions.UPLOAD_LEARNING_PATH_BANNER_COVER_ERROR:
      return failedState(state, 'uploadLearningPathBannerCoverState', error);

    case learningPathActions.UPLOAD_LEARNING_PATH_LINE_IMAGE_REQUEST:
      return loadingState(state, 'uploadLearningPathLineImageState');
    case learningPathActions.UPLOAD_LEARNING_PATH_LINE_IMAGE_SUCCESS:
      return successStateWithData(state, 'uploadLearningPathLineImageState', payload);
    case learningPathActions.UPLOAD_LEARNING_PATH_LINE_IMAGE_ERROR:
      return failedState(state, 'uploadLearningPathLineImageState', error);

    case learningPathActions.UPLOAD_LEARNING_PATH_FACEBOOK_IMAGE_REQUEST:
      return loadingState(state, 'uploadLearningPathFacebookImageState');
    case learningPathActions.UPLOAD_LEARNING_PATH_FACEBOOK_IMAGE_SUCCESS:
      return successStateWithData(state, 'uploadLearningPathFacebookImageState', payload);
    case learningPathActions.UPLOAD_LEARNING_PATH_FACEBOOK_IMAGE_ERROR:
      return failedState(state, 'uploadLearningPathFacebookImageState', error);

    case learningPathActions.DELETE_LEARNING_PATH_REQUEST:
      return loadingState(state, 'deleteLearningPathState');
    case learningPathActions.DELETE_LEARNING_PATH_SUCCESS:
      return successStateWithData(state, 'deleteLearningPathState', payload);
    case learningPathActions.DELETE_LEARNING_PATH_ERROR:
      return failedState(state, 'deleteLearningPathState', error);
    case learningPathActions.LEARNING_PATH_SECTION_ITEM_LIST_REQUEST:
      return loadingState(state, 'learningPathSectionItemList');
    case learningPathActions.LEARNING_PATH_SECTION_ITEM_LIST_SUCCESS:
      return successStateWithData(state, 'learningPathSectionItemList', payload);
    case learningPathActions.LEARNING_PATH_SECTION_ITEM_LIST_ERROR:
      return failedState(state, 'learningPathSectionItemList', error);
    case learningPathActions.LEARNING_PATH_SECTION_ITEM_LIST_UPDATE:
      return successStateWithData(
        state,
        'learningPathSectionItemList',
        state.learningPathSectionItemList.data !== null
          ? [...state.learningPathSectionItemList.data, ...payload]
          : payload,
      );

    case learningPathActions.FETCH_LEARNING_PATH_COURSE_BUNDLE_LIST_REQUEST:
      return loadingState(state, 'learningPathCourseBundleList');
    case learningPathActions.FETCH_LEARNING_PATH_COURSE_BUNDLE_LIST_SUCCESS:
      return successStateWithData(state, 'learningPathCourseBundleList', payload);
    case learningPathActions.FETCH_LEARNING_PATH_COURSE_BUNDLE_LIST_ERROR:
      return failedState(state, 'learningPathCourseBundleList', error);
    case learningPathActions.FETCH_LEARNING_PATH_COURSE_BUNDLE_LIST_RESET:
      return resetState(state, 'learningPathCourseBundleList');

    case learningPathActions.FETCH_LEARNING_PATH_DETAIL_REQUEST:
      return loadingState(state, 'learningPathDetailState');
    case learningPathActions.FETCH_LEARNING_PATH_DETAIL_SUCCESS:
      return successStateWithData(state, 'learningPathDetailState', payload);
    case learningPathActions.FETCH_LEARNING_PATH_DETAIL_ERROR:
      return failedState(state, 'learningPathDetailState', error);
    case learningPathActions.FETCH_LEARNING_PATH_DETAIL_RESET:
      return resetState(state, 'learningPathDetailState');

    case learningPathActions.FETCH_LEARNING_PATH_DETAIL_SEO_REQUEST:
      return loadingState(state, 'learningPathDetailSeoState');
    case learningPathActions.FETCH_LEARNING_PATH_DETAIL_SEO_SUCCESS:
      return successStateWithData(state, 'learningPathDetailSeoState', payload);
    case learningPathActions.FETCH_LEARNING_PATH_DETAIL_SEO_ERROR:
      return failedState(state, 'learningPathDetailSeoState', error);
    case learningPathActions.FETCH_LEARNING_PATH_DETAIL_SEO_RESET:
      return resetState(state, 'learningPathDetailSeoState');

    case learningPathActions.CREATE_LEARNING_PATH_SHARE_LOG_REQUEST:
      return loadingState(state, 'createLearningPathShareLogState');
    case learningPathActions.CREATE_LEARNING_PATH_SHARE_LOG_SUCCESS:
      return successStateWithData(state, 'createLearningPathShareLogState', payload);
    case learningPathActions.CREATE_LEARNING_PATH_SHARE_LOG_ERROR:
      return failedState(state, 'createLearningPathShareLogState', error);
    case learningPathActions.CREATE_LEARNING_PATH_SHARE_LOG_RESET:
      return resetState(state, 'createLearningPathShareLogState');

    case learningPathActions.RESET_ALL_STATE:
      return initLearningPathState;

    default:
      return state;
  }
}
