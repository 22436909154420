import { isApiResponseSuccess } from '@common/utility/http';
import { all, call, put, takeEvery } from 'redux-saga/effects';

import courseActions from '@redux/course/actions';
import courseAPI from '@redux/course/api';
import {
  CreateOrUpdateCourseReviewRequestAction,
  FetchCourseDetailCurriculumRequestAction,
  FetchCourseDetailGlancesRequestAction,
  FetchCourseDetailRequestAction,
  FetchCourseReviewListRequestAction,
  FetchCourseReviewSummaryRequestAction,
  FetchCourseEnrollmentRequestAction,
  HttpCreateOrUpdateCourseReviewApiResponse,
  HttpFetchCourseDetailApiResponse,
  HttpFetchCourseDetailCurriculumApiResponse,
  HttpFetchCourseDetailGlancesApiResponse,
  HttpFetchCourseReviewListApiResponse,
  HttpFetchCourseReviewSummaryApiResponse,
  HttpFetchCourseEnrollmentApiResponse,
  FetchCourseReviewMeRequestAction,
  HttpFetchCourseReviewMeApiResponse,
  FetchCourseDiscussionListRequestAction,
  HttpFetchCourseDiscussionListApiResponse,
  CreateCourseDiscussionRequestAction,
  HttpCreateCourseDiscussionApiResponse,
  AddCourseDiscussionReplyRequestAction,
  HttpAddCourseDiscussionReplyApiResponse,
  FetchCourseCertificateBundlesRequestAction,
  HttpFetchCourseCertificateBundlesApiResponse,
  FetchCourseSuggestionsRequestAction,
  HttpFetchCourseSuggestionsApiResponse,
  HttpFetchCourseDetailSeoApiResponse,
} from '@redux/course/type/courseDetailType';
import { FetchCourseSeminarRequestAction, HttpFetchCourseSeminarApiResponse } from '@redux/course/type/seminarType';

const {
  fetchCourseDetailSeoAction,
  fetchCourseDetailAction,
  fetchCourseEnrollmentAction,
  fetchCourseDetailGlancesAction,
  fetchCourseDetailCurriculumAction,
  fetchCourseReviewListAction,
  fetchCourseReviewMeAction,
  fetchCourseReviewSummaryAction,
  createOrUpdateCourseReviewAction,
  fetchCourseDiscussionListAction,
  createCourseDiscussionAction,
  addCourseDiscussionReplyAction,
  fetchCourseCertificateBundlesAction,
  fetchCourseSeminarAction,
  fetchCourseSuggestionsAction,
} = courseActions;

function* fetchCourseDetailSeoSaga(action: { type: typeof courseActions; payload: FetchCourseDetailRequestAction }) {
  const apiResponse: HttpFetchCourseDetailSeoApiResponse = yield call(courseAPI.fetchCourseDetailSeo, action.payload);
  if (isApiResponseSuccess(apiResponse.status)) {
    yield put(fetchCourseDetailSeoAction.success(apiResponse.data));
  } else {
    yield put(fetchCourseDetailAction.error(apiResponse));
  }
}

function* fetchCourseDetailSaga(action: { type: typeof courseActions; payload: FetchCourseDetailRequestAction }) {
  const apiResponse: HttpFetchCourseDetailApiResponse = yield call(courseAPI.fetchCourseDetail, action.payload);
  if (isApiResponseSuccess(apiResponse.status)) {
    yield put(fetchCourseDetailAction.success(apiResponse.data));
  } else {
    yield put(fetchCourseDetailAction.error(apiResponse));
  }
}

function* fetchCourseEnrollmentSaga(action: {
  type: typeof courseActions;
  payload: FetchCourseEnrollmentRequestAction;
}) {
  const apiResponse: HttpFetchCourseEnrollmentApiResponse = yield call(courseAPI.fetchCourseEnrollment, action.payload);
  if (isApiResponseSuccess(apiResponse.status)) {
    yield put(fetchCourseEnrollmentAction.success(apiResponse.data));
  } else {
    yield put(fetchCourseEnrollmentAction.error(apiResponse));
  }
}

function* fetchCourseDetailGlancesSaga(action: {
  type: typeof courseActions;
  payload: FetchCourseDetailGlancesRequestAction;
}) {
  const apiResponse: HttpFetchCourseDetailGlancesApiResponse = yield call(
    courseAPI.fetchCourseDetailGlances,
    action.payload,
  );
  if (isApiResponseSuccess(apiResponse.status)) {
    yield put(fetchCourseDetailGlancesAction.success(apiResponse.data));
  } else {
    yield put(fetchCourseDetailGlancesAction.error(apiResponse));
  }
}

function* fetchCourseDetailCurriculumSaga(action: {
  type: typeof courseActions;
  payload: FetchCourseDetailCurriculumRequestAction;
}) {
  const apiResponse: HttpFetchCourseDetailCurriculumApiResponse = yield call(
    courseAPI.fetchCourseDetailCurriculum,
    action.payload,
  );
  if (isApiResponseSuccess(apiResponse.status)) {
    yield put(fetchCourseDetailCurriculumAction.success(apiResponse.data));
  } else {
    yield put(fetchCourseDetailCurriculumAction.error(apiResponse));
  }
}

function* fetchCourseReviewListSaga(action: {
  type: typeof courseActions;
  payload: FetchCourseReviewListRequestAction;
}) {
  const apiResponse: HttpFetchCourseReviewListApiResponse = yield call(courseAPI.fetchCourseReviewList, action.payload);
  if (isApiResponseSuccess(apiResponse.status)) {
    const { limit, offset } = action.payload;
    yield put(
      fetchCourseReviewListAction.success({
        ...apiResponse.data,
        page: offset / limit + 1,
      }),
    );
  } else {
    yield put(fetchCourseReviewListAction.error(apiResponse));
  }
}

function* fetchCourseReviewMeSaga(action: { type: typeof courseActions; payload: FetchCourseReviewMeRequestAction }) {
  const apiResponse: HttpFetchCourseReviewMeApiResponse = yield call(courseAPI.fetchCourseReviewMe, action.payload);
  if (isApiResponseSuccess(apiResponse.status)) {
    yield put(fetchCourseReviewMeAction.success(apiResponse.data));
  } else {
    yield put(fetchCourseReviewMeAction.error(apiResponse));
  }
}

function* fetchCourseReviewSummarySaga(action: {
  type: typeof courseActions;
  payload: FetchCourseReviewSummaryRequestAction;
}) {
  const apiResponse: HttpFetchCourseReviewSummaryApiResponse = yield call(
    courseAPI.fetchCourseReviewSummary,
    action.payload,
  );
  if (isApiResponseSuccess(apiResponse.status)) {
    yield put(fetchCourseReviewSummaryAction.success(apiResponse.data));
  } else {
    yield put(fetchCourseReviewSummaryAction.error(apiResponse));
  }
}

function* createOrUpdateCourseReviewSaga(action: {
  type: typeof courseActions;
  payload: CreateOrUpdateCourseReviewRequestAction;
}) {
  const apiResponse: HttpCreateOrUpdateCourseReviewApiResponse = yield call(
    courseAPI.createOrUpdateCourseReview,
    action.payload,
  );
  if (isApiResponseSuccess(apiResponse.status)) {
    yield put(createOrUpdateCourseReviewAction.success(apiResponse.data));
  } else {
    yield put(createOrUpdateCourseReviewAction.error(apiResponse));
  }
}

function* fetchCourseDiscussionListSaga(action: {
  type: typeof courseActions;
  payload: FetchCourseDiscussionListRequestAction;
}) {
  const apiResponse: HttpFetchCourseDiscussionListApiResponse = yield call(
    courseAPI.fetchCourseDiscussionList,
    action.payload,
  );
  if (isApiResponseSuccess(apiResponse.status)) {
    yield put(fetchCourseDiscussionListAction.success(apiResponse.data));
  } else {
    yield put(fetchCourseDiscussionListAction.error(apiResponse));
  }
}

function* createCourseDiscussionSaga(action: {
  type: typeof courseActions;
  payload: CreateCourseDiscussionRequestAction;
}) {
  const apiResponse: HttpCreateCourseDiscussionApiResponse = yield call(
    courseAPI.createCourseDiscussion,
    action.payload,
  );
  if (isApiResponseSuccess(apiResponse.status)) {
    yield put(createCourseDiscussionAction.success(apiResponse.data));
  } else {
    yield put(createCourseDiscussionAction.error(apiResponse));
  }
}

function* addCourseDiscussionReplySaga(action: {
  type: typeof courseActions;
  payload: AddCourseDiscussionReplyRequestAction;
}) {
  const apiResponse: HttpAddCourseDiscussionReplyApiResponse = yield call(
    courseAPI.addCourseDiscussionReply,
    action.payload,
  );
  if (isApiResponseSuccess(apiResponse.status)) {
    yield put(addCourseDiscussionReplyAction.success(apiResponse.data));
  } else {
    yield put(addCourseDiscussionReplyAction.error(apiResponse));
  }
}

function* fetchCourseCertificateBundlesSaga(action: {
  type: typeof courseActions;
  payload: FetchCourseCertificateBundlesRequestAction;
}) {
  const apiResponse: HttpFetchCourseCertificateBundlesApiResponse = yield call(
    courseAPI.fetchCourseCertificateBundles,
    action.payload,
  );
  if (isApiResponseSuccess(apiResponse.status)) {
    yield put(fetchCourseCertificateBundlesAction.success(apiResponse.data));
  } else {
    yield put(fetchCourseCertificateBundlesAction.error(apiResponse));
  }
}

function* fetchCourseSeminarSaga(action: { type: typeof courseActions; payload: FetchCourseSeminarRequestAction }) {
  const apiResponse: HttpFetchCourseSeminarApiResponse = yield call(courseAPI.fetchCourseSeminar, action.payload);
  if (isApiResponseSuccess(apiResponse.status)) {
    yield put(fetchCourseSeminarAction.success(apiResponse.data));
  } else {
    yield put(fetchCourseSeminarAction.error(apiResponse));
  }
}

function* fetchCourseSuggestionsSaga(action: {
  type: typeof courseActions;
  payload: FetchCourseSuggestionsRequestAction;
}) {
  const apiResponse: HttpFetchCourseSuggestionsApiResponse = yield call(
    courseAPI.fetchCourseSuggestions,
    action.payload,
  );
  if (isApiResponseSuccess(apiResponse.status)) {
    yield put(fetchCourseSuggestionsAction.success(apiResponse.data));
  } else {
    yield put(fetchCourseSuggestionsAction.error(apiResponse));
  }
}

export default function* courseSaga() {
  yield all([takeEvery(courseActions.FETCH_COURSE_DETAIL_SEO_REQUEST, fetchCourseDetailSeoSaga)]);
  yield all([takeEvery(courseActions.FETCH_COURSE_DETAIL_REQUEST, fetchCourseDetailSaga)]);
  yield all([takeEvery(courseActions.FETCH_COURSE_ENROLLMENT_REQUEST, fetchCourseEnrollmentSaga)]);
  yield all([takeEvery(courseActions.FETCH_COURSE_DETAIL_GLANCES_REQUEST, fetchCourseDetailGlancesSaga)]);
  yield all([takeEvery(courseActions.FETCH_COURSE_DETAIL_CURRICULUM_REQUEST, fetchCourseDetailCurriculumSaga)]);
  yield all([takeEvery(courseActions.FETCH_COURSE_DETAIL_REVIEW_LIST_REQUEST, fetchCourseReviewListSaga)]);
  yield all([takeEvery(courseActions.FETCH_COURSE_DETAIL_REVIEW_ME_REQUEST, fetchCourseReviewMeSaga)]);
  yield all([takeEvery(courseActions.FETCH_COURSE_DETAIL_REVIEW_SUMMARY_REQUEST, fetchCourseReviewSummarySaga)]);
  yield all([takeEvery(courseActions.CREATE_OR_UPDATE_COURSE_DETAIL_REVIEW_REQUEST, createOrUpdateCourseReviewSaga)]);
  yield all([takeEvery(courseActions.FETCH_COURSE_DETAIL_DISCUSSION_LIST_REQUEST, fetchCourseDiscussionListSaga)]);
  yield all([takeEvery(courseActions.CREATE_COURSE_DISCUSSION_REQUEST, createCourseDiscussionSaga)]);
  yield all([takeEvery(courseActions.ADD_COURSE_DISCUSSION_REPLY_REQUEST, addCourseDiscussionReplySaga)]);
  yield all([takeEvery(courseActions.FETCH_COURSE_CERTIFICATE_BUNDLES_REQUEST, fetchCourseCertificateBundlesSaga)]);
  yield all([takeEvery(courseActions.FETCH_COURSE_SEMINAR_REQUEST, fetchCourseSeminarSaga)]);
  yield all([takeEvery(courseActions.FETCH_COURSE_SUGGESTIONS_REQUEST, fetchCourseSuggestionsSaga)]);
}
