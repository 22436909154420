import {
  CreateCertificatePayload,
  CreateOrUpdateFinishedLogPayload,
  LearningPageCourseItemStatus,
} from '@interfaces/learning';
import { IQuizSubmissionPayload, StartQuizParams } from '@interfaces/quiz';
import { IUserOfficialInfo } from '@interfaces/user';

const actions = {
  FETCH_LEARNING_COURSE_REQUEST: 'learning/FETCH_LEARNING_COURSE_REQUEST',
  FETCH_LEARNING_COURSE_SUCCESS: 'learning/FETCH_LEARNING_COURSE_SUCCESS',
  FETCH_LEARNING_COURSE_ERROR: 'learning/FETCH_LEARNING_COURSE_ERROR',
  SET_USER_ANSWERS: 'learning/SET_USER_ANSWERS',
  FETCH_QUIZ_SUMMARY_SCORE_REQUEST: 'learning/FETCH_QUIZ_SUMMARY_SCORE_REQUEST',
  FETCH_QUIZ_SUMMARY_SCORE_SUCCESS: 'learning/FETCH_QUIZ_SUMMARY_SCORE_SUCCESS',
  FETCH_QUIZ_SUMMARY_SCORE_ERROR: 'learning/FETCH_QUIZ_SUMMARY_SCORE_ERROR',
  // TODO: imporve to use class action for reduce repeat REQUEST, SUCCESS, ERROR action for every usecase
  CREATE_OR_UPDATE_FINISHED_LOG_REQUEST: 'learning/CREATE_OR_UPDATE_FINISHED_LOG_REQUEST',
  CREATE_OR_UPDATE_FINISHED_LOG_SUCCESS: 'learning/CREATE_OR_UPDATE_FINISHED_LOG_SUCCESS',
  CREATE_OR_UPDATE_FINISHED_LOG_ERROR: 'learning/CREATE_OR_UPDATE_FINISHED_LOG_ERROR',
  SET_USER_ANSWERS_REQUEST: 'learning/SET_USER_ANSWER_REQUEST',
  SET_USER_ANSWERS_SUCCESS: 'learning/SET_USER_ANSWERS_SUCCESS',
  SET_USER_ANSWERS_ERROR: 'learning/SET_USER_ANSWERS_ERROR',
  GET_QUIZ_SUBMISSION_REQUEST: 'learning/GET_QUIZ_SUBMISSION_REQUEST',
  GET_QUIZ_SUBMISSION_SUCCESS: 'learning/GET_QUIZ_SUBMISSION_SUCCESS',
  GET_QUIZ_SUBMISSION_ERROR: 'learning/GET_QUIZ_SUBMISSION_ERROR',
  CREATE_CERTIFICATE_REQUEST: 'learning/CREATE_CERTIFICATE_REQUEST',
  CREATE_CERTIFICATE_SUCCESS: 'learning/CREATE_CERTIFICATE_SUCCESS',
  CREATE_CERTIFICATE_ERROR: 'learning/CREATE_CERTIFICATE_ERROR',
  UPDATE_LEARNING_PATH_PROGRESSION_REQUEST: 'learning/UPDATE_LEARNING_PATH_PROGRESSION_REQUEST',
  UPDATE_LEARNING_PATH_PROGRESSION_SUCCESS: 'learning/UPDATE_LEARNING_PATH_PROGRESSION_SUCCESS',
  UPDATE_LEARNING_PATH_PROGRESSION_ERROR: 'learning/UPDATE_LEARNING_PATH_PROGRESSION_ERROR',
  CLEAR_UPDATE_LEARNING_PATH_PROGRESSION_ERROR: 'learning/CLEAR_UPDATE_LEARNING_PATH_PROGRESSION_ERROR',
  GET_CERTIFICATE_PROGRESSION_REQUEST: 'learning/GET_CERTIFICATE_PROGRESSION_REQUEST',
  GET_CERTIFICATE_PROGRESSION_SUCCESS: 'learning/GET_CERTIFICATE_PROGRESSION_SUCCESS',
  GET_CERTIFICATE_PROGRESSION_ERROR: 'learning/GET_CERTIFICATE_PROGRESSION_ERROR',
  CLEAR_GET_CERTIFICATE_PROGRESSION_ERROR: 'learning/CLEAR_GET_CERTIFICATE_PROGRESSION_ERROR',
  START_QUIZ_REQUEST: 'learning/START_QUIZ_REQUEST',
  START_QUIZ_REQUEST_SUCCESS: 'learning/START_QUIZ_REQUEST_SUCCESS',
  START_QUIZ_REQUEST_ERROR: 'learning/START_QUIZ_REQUEST_ERROR',
  CLEAR_CREATE_CERTIFICATE_ERROR: 'learning/CLEAR_CREATE_CERTIFICATE_ERROR',
  FETCH_REVIEWS_REQUEST: 'learning/FETCH_REVIEWS_REQUEST',
  FETCH_REVIEWS_SUCCESS: 'learning/FETCH_REVIEWS_SUCCESS',
  FETCH_REVIEWS_ERROR: 'learning/FETCH_REVIEWS_ERROR',
  CREATE_OR_UPDATE_REVIEW_REQUEST: 'learning/CREATE_OR_UPDATE_REVIEW_REQUEST',
  CREATE_OR_UPDATE_REVIEW_SUCCESS: 'learning/CREATE_OR_UPDATE_REVIEW_SUCCESS',
  CREATE_OR_UPDATE_REVIEW_ERROR: 'learning/CREATE_OR_UPDATE_REVIEW_ERROR',
  FETCH_REVIEWS_SUMMARY_REQUEST: 'learning/FETCH_REVIEWS_SUMMARY_REQUEST',
  FETCH_REVIEWS_SUMMARY_SUCCESS: 'learning/FETCH_REVIEWS_SUMMARY_SUCCESS',
  FETCH_REVIEWS_SUMMARY_ERROR: 'learning/FETCH_REVIEWS_SUMMARY_ERROR',
  UPDATE_REVIEW_SKIP_LOG_REQUEST: 'learning/UPDATE_REVIEW_SKIP_LOG_REQUEST',
  UPDATE_REVIEW_SKIP_LOG_SUCCESS: 'learning/UPDATE_REVIEW_SKIP_LOG_SUCCESS',
  UPDATE_REVIEW_SKIP_LOG_ERROR: 'learning/UPDATE_REVIEW_SKIP_LOG_ERROR',
  FETCH_DISCUSSION_REQUEST: 'learning/FETCH_DISCUSSION_REQUEST',
  FETCH_DISCUSSION_SUCCESS: 'learning/FETCH_DISCUSSION_SUCCESS',
  FETCH_DISCUSSION_ERROR: 'learning/FETCH_DISCUSSION_ERROR',
  CREATE_DISCUSSION_REQUEST: 'learning/CREATE_DISCUSSION_REQUEST',
  CREATE_DISCUSSION_SUCCESS: 'learning/CREATE_DISCUSSION_SUCCESS',
  CREATE_DISCUSSION_ERROR: 'learning/CREATE_DISCUSSION_ERROR',
  CREATE_DISCUSSION_REPLY_REQUEST: 'learning/CREATE_DISCUSSION_REPLY_REQUEST',
  CREATE_DISCUSSION_REPLY_SUCCESS: 'learning/CREATE_DISCUSSION_REPLY_SUCCESS',
  CREATE_DISCUSSION_REPLY_ERROR: 'learning/CREATE_DISCUSSION_REPLY_ERROR',

  CLEAR_QUIZ_SUMMARY_SCORE: 'learning/CLEAR_QUIZ_SUMMARY_SCORE',
  SET_RETEST_STATE: 'learning/SET_RETEST_STATE',
  CLAER_RETEST_STATE: 'learning/CLAER_RETEST_STATE',

  fetch_learning_course: (courseId: string, courseItemId?: number) => ({
    type: actions.FETCH_LEARNING_COURSE_REQUEST,
    courseId,
    courseItemId,
  }),

  set_user_answers: (quizSubmission: IQuizSubmissionPayload, quizSubmissionId: number, quizId: number) => ({
    type: actions.SET_USER_ANSWERS_REQUEST,
    quizSubmissionId,
    quizSubmission,
    quizId,
  }),

  fetch_quiz_summary_score: (quizId: number) => ({
    type: actions.FETCH_QUIZ_SUMMARY_SCORE_REQUEST,
    quizId,
  }),
  create_or_update_finished_log: (payload: CreateOrUpdateFinishedLogPayload, courseIdOrIdName: string) => ({
    type: actions.CREATE_OR_UPDATE_FINISHED_LOG_REQUEST,
    data: {
      ...payload,
      courseItemStatus: payload.finished
        ? LearningPageCourseItemStatus.COMPLETED
        : LearningPageCourseItemStatus.PROGRESS,
    },
    courseIdOrIdName,
  }),

  get_quiz_submission: (quizId: number) => ({
    type: actions.GET_QUIZ_SUBMISSION_REQUEST,
    quizId,
  }),

  create_certificate: (payload: CreateCertificatePayload) => ({
    type: actions.CREATE_CERTIFICATE_REQUEST,
    createCertificatePayload: payload,
  }),

  start_quiz: (payload: StartQuizParams) => ({
    type: actions.START_QUIZ_REQUEST,
    payload,
  }),

  clear_certicicate_error: () => ({
    type: actions.CLEAR_CREATE_CERTIFICATE_ERROR,
  }),

  fetch_reviews: (limit: number, courseIdOrIdName: string) => ({
    type: actions.FETCH_REVIEWS_REQUEST,
    limit,
    courseIdOrIdName,
  }),

  create_or_update_review: (courseIdOrIdName: string, rating: number, comment?: string) => ({
    type: actions.CREATE_OR_UPDATE_REVIEW_REQUEST,
    courseIdOrIdName,
    rating,
    comment,
  }),

  fetch_reviews_summary: (courseIdOrIdName: string) => ({
    type: actions.FETCH_REVIEWS_SUMMARY_REQUEST,
    courseIdOrIdName,
  }),

  set_retest_state: () => ({
    type: actions.SET_RETEST_STATE,
  }),

  clear_retest_state: () => ({
    type: actions.CLAER_RETEST_STATE,
  }),

  clear_quiz_summary_score: () => ({
    type: actions.CLEAR_QUIZ_SUMMARY_SCORE,
  }),

  update_review_skip_log: (courseIdOrIdName: string) => ({
    type: actions.UPDATE_REVIEW_SKIP_LOG_REQUEST,
    courseIdOrIdName,
  }),
  fetch_discussion: (courseIdOrIdName: string, params: { limit: number; keyword?: string; discussionId?: number }) => ({
    type: actions.FETCH_DISCUSSION_REQUEST,
    courseIdOrIdName,
    params,
  }),
  create_discussion: (courseIdOrIdName: string, payload: { title: string; chapterId?: number }) => ({
    type: actions.CREATE_DISCUSSION_REQUEST,
    courseIdOrIdName,
    payload,
  }),
  create_discussion_reply: (
    courseIdOrIdName: string,
    discussionId: number,
    params: { limit: number; keyword?: string },
    payload: { content: string; chapterId?: number },
  ) => ({
    type: actions.CREATE_DISCUSSION_REPLY_REQUEST,
    courseIdOrIdName,
    discussionId,
    params,
    payload,
  }),
  get_certificate_progression: (courseIdOrIdName: string) => ({
    type: actions.GET_CERTIFICATE_PROGRESSION_REQUEST,
    courseIdOrIdName,
  }),
  clear_certificate_progression_error: () => ({
    type: actions.CLEAR_GET_CERTIFICATE_PROGRESSION_ERROR,
  }),
  update_learning_path_progression: (officialInfo: IUserOfficialInfo) => ({
    type: actions.UPDATE_LEARNING_PATH_PROGRESSION_REQUEST,
    officialInfo,
  }),
  clear_update_learning_path_progression_error: () => ({
    type: actions.CLEAR_UPDATE_LEARNING_PATH_PROGRESSION_ERROR,
  }),
};

export default actions;
