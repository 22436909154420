import { isApiResponseSuccess } from '@common/utility/http';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import userAPI from 'src/api/user';
import { ResponseData, ResponseDataV2 } from 'src/helpers/httpClient';

import userActions from './actions';
import {
  CreateOfficialProfileStatusCode,
  ICreateOfficialProfilePayload,
  IGetOtpPayload,
  IGetOfficialProfile,
  ICountry,
  GetCountriesStatusCode,
  GetOfficialProfileStatusCode,
  GetMyCertificatesStatusCode,
  GetMyCertificates,
  FetchModuleCertificateRequestAction,
  HttpFetchModuleCertificateApiResponse,
  HttpFetchMyLearningPathApiResponse,
  FetchMyLearningPathRequestAction,
} from './interface';

import { ICoreApiResponse } from '@interfaces/common';
import { IUser } from '@interfaces/user';

const { fetchModuleCertificateAction, fetchMyLearningPathAction } = userActions;

export function* fetch_ip_address() {
  const response: ResponseData = yield call(userAPI.fetchIpAddress);
  yield put({
    type: userActions.FETCH_IP_ADDRESS_SUCCESS,
    payload: response.data.data,
  });
}

export function* get_countries(action: { type: string }) {
  const response: ResponseDataV2<ICoreApiResponse<ICountry[], GetCountriesStatusCode>> = yield call(
    userAPI.getCountries,
  );

  if (response?.data?.status === '000') {
    yield put({
      type: userActions.GET_COUNTRIES_SUCCESS,
      payload: response.data.data,
      status: response.data.status,
    });
    return;
  }

  yield put({
    type: userActions.GET_COUNTRIES_ERROR,
    payload: response?.data?.data || response?.data,
    status: response?.data?.status,
  });
}

export function* get_official_profile(action: { type: string; payload: { courseIdOrIdName: string } }) {
  const { courseIdOrIdName } = action.payload;
  const response: ResponseDataV2<ICoreApiResponse<IGetOfficialProfile, GetOfficialProfileStatusCode>> = yield call(
    userAPI.getOfficialProfile,
    courseIdOrIdName,
  );

  if (response?.data?.status === '000') {
    yield put({
      type: userActions.GET_OFFICIAL_PROFILE_SUCCESS,
      payload: response.data.data,
      status: response.data.status,
    });
    return;
  }

  yield put({
    type: userActions.GET_OFFICIAL_PROFILE_ERROR,
    payload: response.data?.data || response.data,
    status: response.data?.status,
  });
}

export function* get_otp(action: { type: string; payload: IGetOtpPayload }) {
  const { payload } = action;
  const response: ResponseData = yield call(userAPI.getOtp, payload);

  if (response?.data?.status === '000') {
    const { refCode, expiredAt } = response.data.data;
    yield put({
      type: userActions.GET_OTP_SUCCESS,
      payload: { otpRef: refCode, expiredAt },
      status: response.data.status,
    });
    return;
  }

  yield put({
    type: userActions.GET_OTP_ERROR,
    payload: response.data.data,
    status: response.data.status,
  });
}

export function* create_official_profile(action: { type: string; payload: ICreateOfficialProfilePayload }) {
  const { payload } = action;
  const response: ResponseDataV2<ICoreApiResponse<IUser, CreateOfficialProfileStatusCode>> = yield call(
    userAPI.createOfficialProfile,
    payload,
  );

  if (response.data?.status !== CreateOfficialProfileStatusCode.OTP_NOT_FOUND) {
    yield put({
      type: userActions.CLEAR_OTP,
    });
  }

  if (response?.data?.status === '000') {
    yield put({
      type: userActions.CREATE_OFFICIAL_PROFILE_SUCCESS,
      payload: response.data.data,
      status: response.data.status,
    });
    return;
  }

  yield put({
    type: userActions.CREATE_OFFICIAL_PROFILE_ERROR,
    payload: response.data?.data || response.data,
    status: response.data?.status || response.status,
  });
}

export function* get_my_certificates() {
  const response: ResponseDataV2<ICoreApiResponse<GetMyCertificates, GetMyCertificatesStatusCode>> = yield call(
    userAPI.getMyCertificates,
  );
  if (response?.data?.status === '000') {
    yield put({
      type: userActions.GET_MY_CERTIFICATES_SUCCESS,
      payload: response.data.data,
      status: response.data.status,
    });
    return;
  }

  yield put({
    type: userActions.GET_MY_CERTIFICATES_ERROR,
    payload: response.data?.data || response.data,
    status: response.data?.status,
  });
}

function* fetchCertificateBundleSaga(action: {
  type: typeof userActions;
  payload: FetchModuleCertificateRequestAction;
}) {
  const apiResponse: HttpFetchModuleCertificateApiResponse = yield call(
    userAPI.getMyModuleCertificate,
    action.payload.id,
  );
  if (isApiResponseSuccess(apiResponse.status)) {
    yield put(fetchModuleCertificateAction.success(apiResponse.data));
  } else {
    yield put(fetchModuleCertificateAction.error(apiResponse));
  }
}

function* fetchMyLearningPathSaga(action: { type: typeof userActions; payload: FetchMyLearningPathRequestAction }) {
  const apiResponse: HttpFetchMyLearningPathApiResponse = yield call(userAPI.getMyLearningPath, action.payload);
  if (isApiResponseSuccess(apiResponse.status)) {
    yield put(fetchMyLearningPathAction.success(apiResponse.data));
  } else {
    yield put(fetchMyLearningPathAction.error(apiResponse));
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(userActions.FETCH_IP_ADDRESS_REQUEST, fetch_ip_address),
    takeEvery(userActions.GET_COUNTRIES, get_countries),
    takeEvery(userActions.GET_OTP, get_otp),
    takeEvery(userActions.GET_OFFICIAL_PROFILE, get_official_profile),
    takeEvery(userActions.CREATE_OFFICIAL_PROFILE, create_official_profile),
    takeEvery(userActions.GET_MY_CERTIFICATES, get_my_certificates),
    takeEvery(userActions.FETCH_MODULE_CERTIFICATE_REQUEST, fetchCertificateBundleSaga),
    takeEvery(userActions.FETCH_MY_LEARNING_PATH_REQUEST, fetchMyLearningPathSaga),
  ]);
}
