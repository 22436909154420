import { ICertificateProgress, IDiscussionList, ILearningPageCurriculum, IReview, IReviewList, IReviewSummary } from '@interfaces/learning';
import { IQuizSummaryScore, IQuizSubmission } from '@interfaces/quiz';
import { LearningStatusCode, validateQuizMessage } from 'src/helpers/constants';

export interface ILearningCourseState {
  learning_course: ILearningPageCurriculum | null;
  learning_course_pending: boolean;
  learning_course_error: boolean;
  learning_course_status: LearningStatusCode | null;
  quiz_submissions: any;
  quiz_submissions_pending: boolean;
  quiz_state: QUIZ_STATE;
  is_loading_start_quiz: boolean;
  is_start_quiz_error: boolean;
  start_quiz_error: validateQuizMessage | string | null;
  is_loading_quiz_summary_score: boolean;
  quiz_summary_score: IQuizSummaryScore | null;
  is_sending_finished_log: boolean;
  is_retest: boolean;
  quiz_submission: IQuizSubmission | null;
  create_certificate_pending: boolean;
  create_certificate_error: boolean;
  create_certificate_success: boolean;
  get_certificate_progression_pending: boolean;
  get_certificate_progression_error: boolean;
  reviews: {
    list: IReviewList;
    summary: IReviewSummary;
  };
  discussions: IDiscussionList;
  limit_discussion: number;
  fetch_review_pending: boolean;
  create_or_update_review_pending: boolean;
  is_create_or_update_review_error: boolean;
  fetch_review_summary_pending: boolean;
  fetch_discussion_pending: boolean;
  create_discussion_pending: boolean;
  is_create_discussion_error: boolean;
  is_discussion_reply_pending: boolean;
  is_create_discussion_reply_error: boolean;
  certificateProgress: ICertificateProgress;
  update_learning_path_progression_pending: boolean;
  update_learning_path_progression_success: boolean;
  update_learning_path_progression_error: boolean;
}

export enum QUIZ_STATE {
  START = 'start',
  DOING = 'doing',
  COMPLETED = 'completed',
  RETEST = 'retest',
}
