import { ApplicantTypeEnum, CPDEnrollmentStatusEnum, CPDLicenseTypeEnum, LicenseRenewalTypeEnum } from 'src/interfaces';

import { convertObjectToQueryString, encryptOrDecryptMessage } from './utils';

export const ROUTES = {
  LOGIN: '/user/auth/skilllane?acr_values=login',
  LOGOUT: '/user/logout',
  FORCE_LOGOUT_AND_LOGIN: '/user/force_logout_and_login',
  SWITCH_ACCOUNT_AFTER_MERGED: '/user/switch_account_after_merged',
  REGISTER: '/user/auth/skilllane?acr_values=register',
  MY_AFFILIATE: '/user/affiliate',
  MY_ACADEMIC_ASSIGNMENT: '/user/my_academic_assignments',
  MY_ACADEMIC_DEGREE: '/user?tab=my-academic-degrees',
  MY_COURSE: '/user?tab=my-courses',
  MY_CERTIFICATE: '/user?tab=my-certificates',
  MY_LEARNING_PATH: '/user?tab=my-learning-path',
  MY_PROFILE: '/user?tab=my-profile',
  MY_TEACHING: '/user/teach',
  MY_INSTRUCTOR_HOMEPAGE: '/instructors/:slug',
  MY_INSTRUCTOR_PROFILE: '/user/instructor_profile',
  MY_INSTRUCTOR_FINANCIAL: '/user/instructor_profile?tab=financial',
  MY_PAYMENT: '/user/history',
  MY_PAGE: '/instructors/:slug',
  MY_REWARD: '/user/redeem',
  MY_WISHLIST: '/wishlists',
  MY_NOTIFICATION: 'user/notification_setting',
  ALL_COURSE: '/courses/all',
  BUSINESS: '/business',
  TEACH: '/teach',
  COURSE_FREE: '/sections/free-popular',
  ALL_INSTRUCTOR: '/instructors',
  SYSTEM_ADMIN: '/system_admins',
  EVALUATION_PORTAL: '/evaluation_portal',
  ORGANIZATION_PORTAL: '/organization_portal/check_certificate',
  HOME: '/',
  EVALUATION_RESULT_LIST: '/university/evaluation-results',
  CPD_LANDING: '/cpd',
  CPD_SEARCH_RESULT: (queryObject: { [key: string]: string }) => {
    const encryptMessage = encryptOrDecryptMessage(queryObject.citizenId, true);
    const decodeCitizenIdObject = {
      ...queryObject,
      citizenId: encryptMessage,
    };

    return `/cpd/courses/search?${convertObjectToQueryString(decodeCitizenIdObject)}`;
  },
  MERGE_USER: '/merge-user',
  SECTIONS_BUNDLE: '/sections/bundle',
  MANAGE_EKYC_USERS: '/admin/management-users/e-kyc',
  EDIT_USER_INFO: (userId: string) => `/system_admins/users/${userId}/edit`,
  LEARNING_PATH_DETAIL: (bundleId: number) => `/learning-path/${bundleId}`,
  ADMIN_LEARNING_PATH: '/admin/learning-path',
  ADMIN_LEARNING_PATH_CREATE: '/admin/learning-path/create',
  ADMIN_LEARNING_PATH_DETAILS: '/admin/learning-path/view/details',
  ADMIN_LEARNING_PATH_IMAGES: '/admin/learning-path/view/images',
  ADMIN_LEARNING_PATH_STUDENT_LIST: '/admin/learning-path/view/student-list',
};

export const GENERAL_MENU = [
  {
    tName: 'all_course',
    name: 'คอร์สทั้งหมด',
    link: ROUTES.ALL_COURSE,
  },
  {
    tName: 'menu.master_degree',
    name: 'master_degree',
    link: '',
  },
  {
    tName: 'skilllane_cpd',
    name: 'SkillLaneCPD',
    link: ROUTES.CPD_LANDING,
  },
  {
    tName: 'business',
    name: 'สำหรับองค์กร',
    link: ROUTES.BUSINESS,
  },
  {
    tName: 'teach',
    name: 'สอนกับเรา',
    link: ROUTES.TEACH,
  },
];

export const BOTTOM_MENU = [
  {
    tName: 'course_free',
    name: 'คอร์สฟรี',
    link: ROUTES.COURSE_FREE,
  },
  {
    tName: 'all_instructor',
    name: 'ผู้สอนทั้งหมด',
    link: ROUTES.ALL_INSTRUCTOR,
  },
];

/**
 * @deprecated remove it, use useUserMenuListing instead
 * */
const USER_MENU = {
  LEARNING: {
    value: 'LEARNING',
    tName: 'skilllane_learning_detail',
    link: '',
  },
  LEARNING_TUXSA: {
    value: 'LEARNING_TUXSA',
    tName: 'tuxsa_learning_detail',
    link: '',
  },
  MY_TEACHING: {
    value: 'MY_TEACHING',
    tName: 'my_teaching',
    link: ROUTES.MY_TEACHING,
  },
  PAYMENT: {
    value: 'PAYMENT',
    tName: 'payment_history',
    link: ROUTES.MY_PAYMENT,
  },
  MY_PAGE: {
    value: 'MY_PAGE',
    tName: 'my_page',
    link: ROUTES.MY_PAGE,
  },
  CPD_COURSE: {
    value: 'CPD_COURSE',
    tName: 'my_skilllane_course_cpd',
    link: '',
  },
  REWARD: {
    value: 'REWARD',
    tName: 'reward',
    link: ROUTES.MY_REWARD,
  },
  INBOX: {
    value: 'INBOX',
    tName: 'my_notification',
    link: '',
  },
  WISHLIST: {
    value: 'WISHLIST',
    tName: 'wishlist',
    link: ROUTES.MY_WISHLIST,
  },
  SYSTEM_ADMIN: {
    value: 'SYSTEM_ADMIN',
    tName: 'system_admin',
    link: ROUTES.SYSTEM_ADMIN,
  },
  PROFILE: {
    value: 'PROFILE',
    tName: 'profile',
    link: ROUTES.MY_PROFILE,
  },
  EVALUATION: {
    value: 'EVALUATION',
    tName: 'evaluation',
    link: ROUTES.EVALUATION_PORTAL,
  },
  ORGANIZATION_PORTAL: {
    value: 'ORGANIZATION_PORTAL',
    tName: 'organization_portal',
    link: ROUTES.ORGANIZATION_PORTAL,
  },
  MY_NOTIFICATION: {
    value: 'MY_NOTIFICATION',
    tName: 'notification_setting',
    link: ROUTES.MY_NOTIFICATION,
  },
  EVALUATION_RESULT_LIST: {
    value: 'EVALUATION_RESULT_LIST',
    tName: 'evaluation_result_list',
    link: ROUTES.EVALUATION_RESULT_LIST,
  },
};

export type MenuType = {
  value: string;
  link: string;
  tName: string;
};

/**
 * @deprecated use UserType enum instead
 */
export const USER_TYPES = {
  STUDENT: 'Student',
  INSTRUCTOR: 'Instructor',
  SYSTEM_ADMIN: 'SystemAdmin',
  INSTRUCTOR_ASSISTANT: 'InstructorAssistant',
  ORGANIZATION_ADMIN: 'OrganizationAdmin',
};

export enum UserType {
  INSTRUCTOR = 'Instructor',
  INSTRUCTOR_ASSISTANT = 'InstructorAssistant',
  ORGANIZATION_ADMIN = 'OrganizationAdmin',
  STUDENT = 'Student',
  SYSTEM_ADMIN = 'SystemAdmin',
}

/**
 * @deprecated use Role enum instead
 */
export const ROLES = {
  STUDENT: 'student',
  INSTRUCTOR: 'instructor',
  PARTNER: 'partner',
  SUPER_ADMIN: 'super_admin',
  ACCOUNTING: 'accounting',
  MARKETING: 'marketing',
  CONTENT_EDITOR: 'content_editor',
  COURSE_EDITOR: 'course_editor',
  SUPPORT: 'support',
  UNIVERSITY_ADMIN: 'university_admin',
};

export enum Role {
  STUDENT = 'student',
  INSTRUCTOR = 'instructor',
  PARTNER = 'partner',
  SUPER_ADMIN = 'super_admin',
  ACCOUNTING = 'accounting',
  MARKETING = 'marketing',
  CONTENT_EDITOR = 'content_editor',
  COURSE_EDITOR = 'course_editor',
  SUPPORT = 'support',
  UNIVERSITY_ADMIN = 'university_admin',
}

/**
 * @deprecated remove it, use useUserMenuListing instead
 * */
export const MENU_BY_USER_TYPE: { [key: string]: Array<MenuType> } = {
  Student: [
    USER_MENU.LEARNING,
    USER_MENU.LEARNING_TUXSA,
    USER_MENU.CPD_COURSE,
    USER_MENU.PAYMENT,
    USER_MENU.REWARD,
    USER_MENU.INBOX,
    USER_MENU.WISHLIST,
    USER_MENU.MY_NOTIFICATION,
  ],
  Instructor: [
    USER_MENU.LEARNING,
    USER_MENU.LEARNING_TUXSA,
    USER_MENU.CPD_COURSE,
    USER_MENU.MY_TEACHING,
    USER_MENU.MY_PAGE,
    USER_MENU.PAYMENT,
    USER_MENU.REWARD,
    USER_MENU.INBOX,
    USER_MENU.WISHLIST,
    USER_MENU.MY_NOTIFICATION,
  ],
  SystemAdmin: [
    USER_MENU.LEARNING,
    USER_MENU.LEARNING_TUXSA,
    USER_MENU.CPD_COURSE,
    USER_MENU.SYSTEM_ADMIN,
    USER_MENU.PAYMENT,
    USER_MENU.REWARD,
    USER_MENU.INBOX,
    USER_MENU.WISHLIST,
    USER_MENU.MY_NOTIFICATION,
  ],
  InstructorAssistant: [USER_MENU.PROFILE, USER_MENU.EVALUATION, USER_MENU.CPD_COURSE, USER_MENU.MY_NOTIFICATION],
  OrganizationAdmin: [
    USER_MENU.PROFILE,
    USER_MENU.ORGANIZATION_PORTAL,
    USER_MENU.CPD_COURSE,
    USER_MENU.MY_NOTIFICATION,
  ],
};

/**
 * @deprecated remove it, use useUserMenuListing instead
 * */
export const MENU_BY_USER_ROLE: { [key: string]: MenuType[] } = {
  [ROLES.UNIVERSITY_ADMIN]: [USER_MENU.EVALUATION_RESULT_LIST],
};

export const BASE_URL = {
  IDENTITY_URL: 'https://account.skilllane.com',
  CERTIFICATE_URL: 'https://certificate.skilllane.com',
};

export enum ACADEMIC_TYPE {
  REGULAR = 'regular',
  AUDIT = 'audit',
  CREDIT = 'credit',
}

export enum LEARNING_STATUS {
  IN_PROGRESS = 'InProgress',
  DONE = 'Done',
  FAILED = 'Failed',
  WAIT_FOR_GRADE = 'WaitForGrade',
  PASSED = 'Passed',
}

export enum COURSE_ITEMS_CONTENT_TYPE {
  VIDEO = 'video',
  QUIZ = 'quiz',
  ARTICLE = 'article',
  INFOGRAPHIC = 'infographic',
  SURVEY = 'survey',
}

export enum QUIZ_TYPE {
  REGULAR = 'regular',
  EXAMINATION = 'examination',
  ASSIGNMENT = 'assignment',
}

export enum NOTIFICATION_TYPE {
  DISCUSSION = 'discussion',
  WELCOME_REWARD_POINT = 'welcome_reward_point',
  COURSE_APPROVED = 'course_approved',
  COURSE_NOT_APPROVED = 'course_not_approved',
  COURSE_SAMPLE_VIDEO_APPROVED = 'course_sample_video_approved',
  COURSE_SAMPLE_VIDEO_NOT_APPROVED = 'course_sample_video_not_approved',
}

export enum CookiePolicyState {
  Pending = 'pending',
  Accept = 'accept',
  NotAccept = 'not_accept',
  ModalClosed = 'modal_closed',
}

export enum RegulatorType {
  OIC = 'OIC',
  TSI = 'TSI',
}

export enum LicenseType {
  OIC_LIFE = 'OIC-Life',
  OIC_NON_LIFE = 'OIC-nonLife',
}

export enum SocialShareType {
  Facebook = 1,
  Email = 2,
  Twitter = 3,
  Line = 4,
}

export const LICENSE_SUSPEND = 'license_suspend';

export const CPDEnrollmentFieldNames = {
  citizenId: 'citizenId',
  email: 'email',
  firstName: 'firstName',
  lastName: 'lastName',
  mobile: 'mobile',
  inputPrefix: 'inputPrefix',
  prefix: 'prefix',
  tsiLicenseType: 'tsiLicenseType',
  tsiLicenseName: 'tsiLicenseName',
  tsiLicenseNo: 'tsiLicenseNo',
  tsiStartDate: 'tsiStartDate',
  tsiEndDate: 'tsiEndDate',
  //
  isSelectBrokerOICLifeLicense: 'isSelectBrokerOICLifeLicense',
  oicBrokerLifeLicenseNo: 'oicBrokerLifeLicenseNo',
  oicBrokerLifeStartDate: 'oicBrokerLifeStartDate',
  oicBrokerLifeEndDate: 'oicBrokerLifeEndDate',
  //
  isSelectBrokerOICNonLifeLicense: 'isSelectBrokerOICNonLifeLicense',
  oicBrokerNonLifeLicenseNo: 'oicBrokerNonLifeLicenseNo',
  oicBrokerNonLifeStartDate: 'oicBrokerNonLifeStartDate',
  oicBrokerNonLifeEndDate: 'oicBrokerNonLifeEndDate',
  //
  isSelectAgentOICLifeLicense: 'isSelectAgentOICLifeLicense',
  oicAgentLifeLicenseNo: 'oicAgentLifeLicenseNo',
  oicAgentLifeStartDate: 'oicAgentLifeStartDate',
  oicAgentLifeEndDate: 'oicAgentLifeEndDate',
  //
  isSelectAgentOICNonLifeLicense: 'isSelectAgentOICNonLifeLicense',
  oicAgentNonLifeLicenseNo: 'oicAgentNonLifeLicenseNo',
  oicAgentNonLifeStartDate: 'oicAgentNonLifeStartDate',
  oicAgentNonLifeEndDate: 'oicAgentNonLifeEndDate',
  //
  isSelectOICLifeLicense: 'isSelectOICLifeLicense',
  oicLifeLicenseNo: 'oicLifeLicenseNo',
  oicLifeStartDate: 'oicLifeStartDate',
  oicLifeEndDate: 'oicLifeEndDate',
  isSelectOICNonLifeLicense: 'isSelectOICNonLifeLicense',
  oicNonLifeLicenseNo: 'oicNonLifeLicenseNo',
  oicNonLifeStartDate: 'oicNonLifeStartDate',
  oicNonLifeEndDate: 'oicNonLifeEndDate',
  oicExtendYearType: 'oicExtendYearType', // NON-LIFE | LIFE | BOTH
  oicReductionType: 'oicReductionType', // YES | NO Depend on regulator setting in the course
  isAcceptConsentCPDTermAndPolicy: 'isAcceptConsentCPDTermAndPolicy',
  roundId: 'roundId',
  courseId: 'courseId',
  roundDate: 'roundDate',
  expiredDate: 'expiredDate',
  isLicenseSuspended: 'isLicenseSuspended',
  isConfirmToEnroll: 'isConfirmToEnroll',
  loading: 'loading',
  status: 'status',
  licenseRenewal: 'licenseRenewal',
  isDeduct: 'isDeduct',
  applicantType: 'applicantType',
  licenseTypes: 'licenseTypes',
};

export type CPDEnrollmentForm = {
  firstName: string;
  lastName: string;
  citizenId: string;
  mobile: string;
  prefix: string | null;
  inputPrefix: string | null;
  email: string | null;
  tsiLicenseType: string | null;
  tsiLicenseName: string | null;
  tsiLicenseNo: string | null;
  tsiStartDate: string | null;
  tsiEndDate: string | null;
  //
  isSelectBrokerOICLifeLicense: boolean;
  oicBrokerLifeLicenseNo: string | null;
  oicBrokerLifeStartDate: string | null;
  oicBrokerLifeEndDate: string | null;
  //
  isSelectBrokerOICNonLifeLicense: boolean;
  oicBrokerNonLifeLicenseNo: string | null;
  oicBrokerNonLifeStartDate: string | null;
  oicBrokerNonLifeEndDate: string | null;
  //
  isSelectAgentOICLifeLicense: boolean;
  oicAgentLifeLicenseNo: string | null;
  oicAgentLifeStartDate: string | null;
  oicAgentLifeEndDate: string | null;
  //
  isSelectAgentOICNonLifeLicense: boolean;
  oicAgentNonLifeLicenseNo: string | null;
  oicAgentNonLifeStartDate: string | null;
  oicAgentNonLifeEndDate: string | null;
  //
  isSelectOICLifeLicense: boolean;
  oicLifeLicenseNo: string | null;
  oicLifeStartDate: string | null;
  oicLifeEndDate: string | null;
  isSelectOICNonLifeLicense: boolean;
  oicNonLifeLicenseNo: string | null;
  oicNonLifeStartDate: string | null;
  oicNonLifeEndDate: string | null;
  oicExtendYearType: string | null; // NON-LIFE | LIFE | BOTH only 4+ renewal
  oicReductionType: string | null; // YES | NO Depend on regulator setting in the course and only 4+ renewal
  isAcceptConsentCPDTermAndPolicy: boolean;
  courseId: number | null;
  roundId: string | null;
  roundDate: string | Date | null;
  expiredDate: string | Date | null;
  isLicenseSuspended: boolean;
  isConfirmToEnroll: boolean;
  loading: boolean;
  status: CPDEnrollmentStatusEnum;
  licenseRenewal: LicenseRenewalTypeEnum;
  isDeduct: boolean;
  applicantType: ApplicantTypeEnum;
  licenseTypes: CPDLicenseTypeEnum[];
  courses: SelectedCourseRound[];
};

type SelectedCourseRound = {
  courseId: string;
  courseName: string;
  expiredDate: Date;
  roundDate: Date;
  roundId: string;
};

type PersonEnrollmentInfomationFields =
  | 'citizenId'
  | 'email'
  | 'firstName'
  | 'lastName'
  | 'mobile'
  | 'inputPrefix'
  | 'prefix';

export type PersonSummaryInformation = Record<PersonEnrollmentInfomationFields, string>;
export enum EmailValidateResultCode {
  SyntaxError = 1,
  SpamTrap = 2,
  Disposable = 3,
  AcceptAll = 4,
  Deliverable = 5,
  Invalid = 6,
  Unknown = 7,
  Role = 8,
}

export enum CpdEmailValidateResult {
  Pass = 'pass',
  Uncertain = 'uncertain',
  NotPass = 'not_pass',
}

export type RegulatorAllType = 'all' | 'oic' | 'tsi';

export enum ProductTypeId {
  COURSE = 1,
  BUNDLE = 2,
}

export enum EnrollmentStatus {
  FREE_COURSE = 'FREE_COURSE',
  AVAILABLE = 'AVAILABLE',
  ENROLLED = 'ENROLLED',
  PACKAGE_ENROLLED = 'PACKAGE_ENROLLED',
  ALREADY_PURCHASE_SOME_COURSE = 'ALREADY_PURCHASE_SOME_COURSE',
  COURSE_UNMATCHED = 'COURSE_UNMATCHED',
  NO_ROUND = 'NO_ROUND',
  UNAVAILABLE = 'UNAVAILABLE',
  REGULATOR_UNCHECKED = 'REGULATOR_UNCHECKED',
  ROUND_OUTDATED = 'ROUND_OUTDATED',
}

export enum LearningStatusCode {
  ENROLLED = '000',
  EXPIRED = 'EXPIRED',
  UNENROLL = 'UNENROLL',
  NOT_FOUND = 'NOT_FOUND',
  UNAUTHORIZED = 'UNAUTHORIZED',
}

export enum CourseInteractionStatisticStatusCode {
  SUCCESS = 'SUCCESS',
  COURSE_NOT_FOUND = 'COURSE_NOT_FOUND',
  COURSE_BAD_REQUEST = 'COURSE_BAD_REQUEST',
}

export enum validateQuizStatus {
  PROFILE_INVALID = 'PROFILE_INVALID',
  IS_GRADED = 'IS_GRADED',
}

export enum validateQuizMessage {
  FULLNAME_INVALID = 'fullname invalid',
  OTP_INVALID = 'otp invalid',
  IS_GRADED = "can't start quiz because it's been graded",
}

export enum QuizType {
  REGULAR = 'regular',
  EXAMINATION = 'examination',
  ASSIGNMENT = 'assignment',
}

export enum QuizTypeTranslate {
  regular = 'quiz',
  examination = 'examination',
  assignment = 'assignment',
}

export enum AnswerType {
  EXACT_CORRECT = 'exact_correct',
  PARTIAL_CORRECT = 'partial_correct',
}

export enum QuizSubmissionStatus {
  COMPLETED = 'completed',
  PENDING = 'pending',
  INPROGRESS = 'inprogress',
  EXPIRED = 'expired',
}

export enum QuestionType {
  MULTIPLE_CHOICE = 'multiple_choices',
  MULTIPLE_CHOOSE = 'multiple_choose',
  ESSAY = 'essay',
}

export const ALLOW_SANITIZE_TAGS = [
  'b',
  'i',
  'u',
  'img',
  'em',
  'strong',
  'a',
  'p',
  'h1',
  'h2',
  'h3',
  'h4',
  'h5_deprecated',
  'h6',
  'ul',
  'ol',
  'li',
  'br',
  'div',
  'span',
  'table',
  'thead',
  'tbody',
  'colgroup',
  'tr',
  'th',
  'td',
  'blockquote',
  'hr',
  'sub',
  'sup',
  's',
  'pre',
  'code',
  'figure',
  'figcaption',
];

export const ALLOW_SANITIZE_ATTRIBUTES = {
  '*': [
    'style',
    'data-*',
    'align',
    'alt',
    'center',
    'border',
    'cellpadding',
    'cellspacing',
    'colspan',
    'rowspan',
    'title',
    'src',
  ],
  a: ['href', 'target'],
};

export const WATERMARK_PERIOD_SECOND = 600;

export enum VIDEO_QUALITIES {
  '1080P' = '1080',
  '720P' = '720',
  '480P' = '480',
  'AUTO' = 'Auto',
}

export enum FieldNameOfficialProfileForm {
  OFFICIAL_PREFIX_TH = 'officialPrefixTh',
  OFFICIAL_FIRSTNAME = 'officialFirstname',
  OFFICIAL_LASTNAME = 'officialLastname',
  OFFICIAL_PREFIX_EN = 'officialPrefixEn',
  OFFICIAL_FIRSTNAME_EN = 'officialFirstnameEn',
  OFFICIAL_LASTNAME_EN = 'officialLastnameEn',
  IS_SHOW_CITIZEN_ID = 'isShowCitizenId',
  CITIZEN_ID = 'citizenId',
  PASSPORT_NUMBER = 'passportNumber',
  PASSPORT_NATIONAL = 'passportNational',
  BIRTH_DAY = 'birthday.day',
  BIRTH_MONTH = 'birthday.month',
  BIRTH_YEAR = 'birthday.year',
  PHONE = 'phone',
  PHONE_COUNTRY_ID = 'phoneCountryId',
  OTP_CODE = 'otpCode',
  OTP_REF = 'otpRef',
}

export enum PrefixNameEnValue {
  MR = 'MR.',
  MRS = 'MRS.',
  MISS = 'MISS',
  ETC = 'อื่นๆ',
}

export enum PrefixNameThValue {
  MR = 'นาย',
  MRS = 'นาง',
  MISS = 'นางสาว',
  ETC = 'อื่นๆ',
}

export const CODE_MOBILE_PHONE_THAILAND = ['06', '08', '09'];

export type CertificateType = 'regular' | 'academic' | 'academic_series' | 'certificate_bundles';

export const CertificateTypeEnValue: Record<CertificateType, string> = {
  regular: 'regular',
  academic: 'academic',
  academic_series: 'academic_series',
  certificate_bundles: 'certificate_bundles',
};

export const CertificateTypeThaiValue: Record<CertificateType, string> = {
  regular: 'ทั่วไป',
  academic: 'วิชาปริญญา',
  academic_series: 'ชุดวิชาปริญญา',
  certificate_bundles: 'Learning Path',
};

export enum MyCertificateParams {
  KEYWORD = 'keyword',
  CERTIFICATE_TYPE = 'certificateType',
  UNIVERSITY_ID = 'universityId',
  UNIVERSITY_ALL = 'university-all',
  COURSE_TYPE_ALL = 'course-type-all',
}

export enum EvaluationResultStatus {
  Pending = 'pending',
  Graded = 'graded',
}

export enum EkycType {
  ID_CARD_WEBCAM = 'idCardWebcam',
  ID_CARD_UPLOAD = 'idCardUpload',
  FACE_IMAGE = 'faceImage',
  COMPARE = 'compare',
}

export enum EkycStatus {
  WAITING = 'waiting',
  COMPLETED = 'completed',
}
export enum LearningPathReceivedStatus {
  PENDING = 'pending',
  NOT_RECEIVED = 'not_received',
  RECEIVED = 'received',
  RECEIVED_RETROSPECTIVELY = 'received_retrospectively',
}

export const LearningPathReceivedStatusThaiValue: Record<LearningPathReceivedStatus, string> = {
  not_received: 'ซื้อครบเงื่อนไข',
  pending: 'ซื้อยังไม่ครบเงื่อนไข',
  received: 'ได้รับประกาศนียบัตรแล้ว',
  received_retrospectively: 'ได้รับประกาศนียบัตรแล้ว',
};

export enum LearningPathStatus {
  DRAFT = 'DRAFT',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export const LearningPathMaxCreate = {
  course: 5,
};
