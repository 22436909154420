import { CoreApiResponse, CoreApiStatusResponse } from '@common/type/CoreApiResponseTypes';
import { PaginateResponse } from '@common/type/PaginateType';
import { uniqueId } from 'lodash';

import { LearningPathReceivedStatus, LearningPathStatus } from '@helpers/constants';
import { IMyCourseItem } from '@interfaces/course';
import { DefaultEventState } from '@redux/type';

export enum CertificateType {
  COURSE = 'COURSE',
  CATEGORY = 'CATEGORY',
}

export enum CertificateBundleCondition {
  ALL = 'ALL',
  ANY = 'ANY',
  SOME = 'SOME',
}

export enum ConditionRelation {
  AND = 'AND',
  OR = 'OR',
}

export enum UploadLearningPath {
  LINE = 'LINE',
  FACEBOOK = 'FACEBOOK',
  BANNER = 'BANNER',
  CARD = 'CARD',
  CERTIFICATE_PREVIEW = 'CERTIFICATE_PREVIEW',
}

export enum SaveStatus {
  DRAFT = 'draft',
  CHANGE = 'change',
  PUBLISH = 'publish',
}

export type InstructorDropdownOption = {
  id: number;
  userId?: number;
  fullName?: string;
  firstname?: string;
  lastname?: string;
};

export type SubCourseCategoryDropdownOption = {
  id: number;
  name: string;
  enabled: boolean;
};

export type MainCourseCategoryDropdownOption = {
  id: number;
  name: string;
  enabled: boolean;
  children?: SubCourseCategoryDropdownOption[];
};

export type CourseSelectListType = {
  id: number;
  name: string;
  instructors: InstructorDropdownOption[];
  category: string[];
  subcategory?: string[];
  inactive?: boolean;
  isTu?: boolean;
};

export type ICertificateInstructor = {
  id: number;
  fullName: string;
  firstName: string;
  lastName: string;
  slug: string;
  reviewTotal: number;
  reviewAverageRating: number;
  teachingCourseTotal: number;
  biology: string;
  imageUrl: string;
};

export type ICourseSelectList = {
  id: number;
  courseId: number;
  name: string;
  instructors: ICertificateInstructor[];
  category: string;
  subcategory: string[];
  progression: IMyCourseItem;
  price: number;
  courseIdName: string;
};

export type ICategory = {
  id: number;
  name: string;
  slug: string;
};

export type ICategorySelectList = {
  id: number;
  category: ICategory;
  parentCategory: ICategory;
  courseList: ICourseSelectList[];
  certificateBundleCondition: CertificateBundleCondition;
  requiredCondition: number | null;
  totalCourseAmount: number;
};

export type ICourseSet = {
  id: number;
  order: number;
  courseCertificateBundleCondition: CertificateBundleCondition;
  certificateType: CertificateType;
  courseSelectList: ICourseSelectList[];
  categorySelectList: ICategorySelectList[];
  requiredCondition: number;
};

export type ILearningPathStepList = {
  id: number;
  step: number;
  conditionRelation: ConditionRelation;
  courseSet: ICourseSet[];
};

export type IImage = {
  key: string;
  url: string;
};

export type IAllVersion = {
  id: number;
  learningPathHighlights: {
    videoCount: number | null;
    videoDuration: number | null;
    fileCount: number | null;
    quizCount: number | null;
    articleCount: number | null;
  };
  learningPathStatus: string;
  learningPathName: string;
  slugName: string;
  description: string;
  certificatePreviewImage: string;
  numberCertificateInstructors: number;
  certificateInstructors: ICertificateInstructor[];
  learningPathStepList: ILearningPathStepList[];
  price: number | null;
  cardImage: IImage;
  bannerCoverImage: IImage;
  lineImage: IImage;
  facebookImage: IImage;
  facebookTitle: string;
  facebookDescription: string;
  isUseMailContent: boolean | null;
  mailSubject: string | null;
  mailContent: string | null;
  twitterContent: string | null;
  googleDescription: string | null;
  status: string | null;
  isDeprecated: boolean | null;
};

export type FetchModuleCertificateCourseListState = {
  id: number;
  name: string;
  instructors: InstructorDropdownOption[];
  category?: string[];
  subcategory?: string[];
};

export type FetchModuleCertificateCourseListResponse = PaginateResponse<FetchModuleCertificateCourseListState>;
export type DeleteLearningPathRequestAction = {
  id: string;
  onSuccess?: () => void;
};

export type CategorySelectListType = {
  id: string | number;
  parentCategory: MainCourseCategoryDropdownOption;
  category: SubCourseCategoryDropdownOption;
  certificateBundleCondition?: CertificateBundleCondition;
  requiredCondition: string;
  courseList: FetchModuleCertificateCourseListState[];
  someCourseInactive?: boolean;
};

export type CourseSetType = {
  id: number | string;
  certificateType: CertificateType;
  courseSelectList: CourseSelectListType[];
  categorySelectList: CategorySelectListType[];
  courseCertificateBundleCondition?: CertificateBundleCondition;
  categoryCertificateBundleCondition?: CertificateBundleCondition;
  requiredCondition: string;
};

export type LearningPathStep = {
  id: number | string;
  courseSet: CourseSetType[];
  conditionRelation?: ConditionRelation;
};

export type UploadLearningPathImageRequest = {
  formData: FormData;
  uploadLearningPathType: UploadLearningPath;
};

export type UploadLearningPathImageResponse = {
  url: string;
  key: string;
};

export type CourseBundle = {
  bundleId: number;
  productId: number;
  name: string;
  fullPrice: number;
  sellPrice: number;
};

export type ModuleCertificateCreateState = {
  id?: number;
  learningPathStatus: LearningPathStatus;
  learningPathName: string;
  learningPathNameCache?: string;
  slugName: string;
  certificatePreviewImage?: string;
  description: string;
  learningPathStepList: LearningPathStep[];
  numberCertificateInstructors: number;
  certificateInstructors: InstructorDropdownOption[];
  cardImage?: UploadLearningPathImageResponse;
  bannerCoverImage?: UploadLearningPathImageResponse;
  lineImage?: UploadLearningPathImageResponse;
  facebookImage?: UploadLearningPathImageResponse;
  facebookTitle?: string;
  facebookDescription?: string;
  isUseMailContent?: boolean;
  mailSubject?: string;
  mailContent?: string;
  twitterContent?: string;
  googleDescription?: string;
  courseBundle?: CourseBundle;
  receiverAmount?: number;
  inactivatedDate?: string;
};

export const defaultCategorySelect: CategorySelectListType = {
  id: 0,
  parentCategory: {
    id: 0,
    name: '',
    enabled: false,
  },
  category: {
    id: -1,
    name: '',
    enabled: false,
  },
  certificateBundleCondition: undefined,
  requiredCondition: '',
  courseList: [],
  someCourseInactive: false,
};

export const defaultCourseSet: CourseSetType = {
  id: uniqueId('courseSet_'),
  certificateType: CertificateType.COURSE,
  courseSelectList: [],
  categorySelectList: [],
  courseCertificateBundleCondition: undefined,
  categoryCertificateBundleCondition: undefined,
  requiredCondition: '',
};

export const defaultLearningPathStep: LearningPathStep = {
  id: uniqueId('learningPathStep_'),
  courseSet: [{ ...defaultCourseSet }],
  conditionRelation: undefined,
};

export const defaultModuleCertificateCreate: ModuleCertificateCreateState = {
  learningPathStatus: LearningPathStatus.DRAFT,
  learningPathName: '',
  slugName: '',
  certificatePreviewImage: undefined,
  description: '',
  learningPathStepList: [
    {
      ...defaultLearningPathStep,
    },
  ],
  numberCertificateInstructors: 0,
  certificateInstructors: [],
};

export type FetchLearningPathListRequestAction = {
  searchingWord?: string;
  slug?: string;
  status?: LearningPathStatus;
  page: number;
  pageSize: number;
};

export type LearningPathEntity = {
  id: number;
  name: string;
  slug: string;
  activatedDate: Date;
  receiverAmount: number;
  status: LearningPathStatus;
  deletable: boolean;
  category: string[];
  cardImage: string;
};

export type FetchLearningPathListState = PaginateResponse<LearningPathEntity>;

export type FetchLearningPathListSuccessAction = FetchLearningPathListState;

export type FetchLearningPathListErrorAction = unknown;

export type HttpFetchLearningPathListStatusResponse = CoreApiStatusResponse;

export type HttpFetchLearningPathListApiResponse = CoreApiResponse<
  FetchLearningPathListSuccessAction,
  HttpFetchLearningPathListStatusResponse
>;

export type FetchModuleCertificateCourseListRequest = {
  categoryId: number[];
  subCategoryId?: number[];
};

export type DeleteLearningPathStatusResponse = CoreApiStatusResponse;

export type DeleteLearningPathSuccessAction = {};

export type DeleteLearningPathErrorAction = unknown;

export type HttpDeleteLearningPathApiResponse = CoreApiResponse<
  DeleteLearningPathSuccessAction,
  DeleteLearningPathStatusResponse
>;

export type FetchModuleCertificateAllCourseListRequestAction = {
  searchingWord?: string;
  instructor?: InstructorDropdownOption;
  mainCategory?: MainCourseCategoryDropdownOption;
  subCategory?: SubCourseCategoryDropdownOption;
  page: number;
  pageSize: number;
};

export type FetchModuleCertificateAllCourseListState = PaginateResponse<CourseSelectListType>;

export type FetchModuleCertificateAllCourseListSuccessAction = FetchModuleCertificateAllCourseListState;

export type HttpFetchModuleCertificateAllCourseListStatusResponse = CoreApiStatusResponse;

export type HttpFetchModuleCertificateAllCourseListApiResponse = CoreApiResponse<
  FetchModuleCertificateAllCourseListSuccessAction,
  HttpFetchModuleCertificateAllCourseListStatusResponse
>;
export type CourseCategoryDialogError = {
  parentCategory?: string;
  category?: string;
  bundleCondition?: string;
  requiredCondition?: string;
};

export const defaultCourseCategoryDialogError: CourseCategoryDialogError = {
  parentCategory: undefined,
  category: undefined,
  bundleCondition: undefined,
  requiredCondition: undefined,
};

export type LearningPathStepMissingConditionSetting = {
  learningPathStepId: string;
  courseSetMissingConditionSetting: string[];
};

export type DuplicateLearningPathStepError = {
  learningPathStepId: string;
  courseSetDuplicateItem: string[];
};

export type ModuleCertificateCreateErrorState = {
  learningPathName: string | undefined;
  slugName: string | undefined;
  learningPathStepMissingConditionSetting: LearningPathStepMissingConditionSetting[];
  duplicateLearningPathStepError: DuplicateLearningPathStepError[];
  instructorErrorList: string[];
};

export const defaultModuleCertificateCreateErrorState: ModuleCertificateCreateErrorState = {
  learningPathName: undefined,
  slugName: undefined,
  learningPathStepMissingConditionSetting: [],
  duplicateLearningPathStepError: [],
  instructorErrorList: [],
};

export type PreviewCertificateRequest = {
  learningPathName: string;
  slugName: string;
  instructorsIdList: number[];
};

export type PreviewCertificateResponse = {
  certificateCode: string;
  certificateImage: string;
  certificatePDFUrl: string;
};

export type CategorySelectListRequest = {
  categoryId: number;
  certificateBundleCondition: CertificateBundleCondition;
  requiredCondition?: number;
};

export type CourseSetRequest = {
  order: number;
  courseCertificateBundleCondition: CertificateBundleCondition;
  certificateType: CertificateType;
  categorySelectList: CategorySelectListRequest[];
  courseIdList: number[];
  requiredCondition?: number;
};

export type LearningPathStepListRequest = {
  step: number;
  conditionRelation: ConditionRelation;
  courseSet: CourseSetRequest[];
};

export type SaveModuleCertificateCreateRequest = {
  id?: number;
  isBundleChange: boolean;
  isDraft: boolean;
  isReactivate: boolean;
  name: string;
  slugName: string;
  description: string;
  certificatePreviewImage: string;
  productId?: number;
  instructorIdList: number[];
  learningPathStepList: LearningPathStepListRequest[];
  cardImage?: string;
  bannerCoverImage?: string;
  lineImage?: string;
  facebookImage?: string;
  facebookTitle?: string;
  facebookDescription?: string;
  isUseMailContent?: boolean;
  mailSubject?: string;
  mailContent?: string;
  twitterContent?: string;
  googleDescription?: string;
};

export type SaveModuleCertificateCreateResponse = {
  id: number;
};

export type FetchInstructorDropdownSuccessAction = InstructorDropdownOption[];

export type HttpFetchInstructorDropdownApiResponse = CoreApiResponse<
  FetchInstructorDropdownSuccessAction,
  CoreApiStatusResponse
>;

export type FetchLearningPathStudentListRequestAction = {
  id?: number;
  searchingWord?: string;
  email?: string;
  status?: LearningPathReceivedStatus;
  page: number;
  pageSize: number;
};

export type LearningPathStudentEntity = {
  id: string;
  fullName: string;
  officialFullName: string;
  email: string;
  receivingStatus: LearningPathReceivedStatus;
  completedDate?: Date;
  receivedDate?: Date;
};

export type FetchLearningPathStudentListState = PaginateResponse<LearningPathStudentEntity>;

export type FetchLearningPathStudentListSuccessAction = FetchLearningPathStudentListState;

export type FetchLearningPathStudentListErrorAction = unknown;

export type HttpFetchLearningPathStudentListResponse = CoreApiResponse<
  FetchLearningPathStudentListSuccessAction,
  CoreApiStatusResponse
>;

export type SearchCertificateBySlugRequest = {
  slugName: string;
};

export type SearchCertificateBySlugResponse = {
  html: {
    version: number;
    url: string;
  };
  thumbnail: string;
};

export type LearningPathSectionPagination = {
  count: number;
  items: LearningPathEntity[];
};

export type ModuleCertificateErrorResponse = {
  inactiveCategoryIds: number[];
  inactiveCourseIds: number[];
  isPassValidate: boolean;
  learningPathStepErrorList: [];
  validateErrorList: string[];
  certificateError: string | undefined;
};

export type LearningPathStatusRequest = {
  learningPathId: number;
  status: LearningPathStatus;
};

export type FetchLearningPathCourseBundleListRequestAction = {
  searchingWord?: string;
  page: number;
  pageSize: number;
};

export type FetchLearningPathCourseBundleListResponse = PaginateResponse<CourseBundle>;

export type FetchLearningPathCourseBundleListSuccessAction = FetchLearningPathCourseBundleListResponse;

export type HttpFetchLearningPathCourseBundleListStatusResponse = CoreApiStatusResponse;

export type HttpFetchLearningPathCourseBundleListApiResponse = CoreApiResponse<
  FetchLearningPathCourseBundleListSuccessAction,
  HttpFetchModuleCertificateAllCourseListStatusResponse
>;

export type FetchLearningPathDetailRequestAction = {
  learningPathId: string;
};

export type FetchLearningPathDetailResponse = {
  id: number;
  learningPathHighlights: {
    videoCount: number | null;
    videoDuration: number | null;
    fileCount: number | null;
    quizCount: number | null;
    articleCount: number | null;
  };
  learningPathStatus: string;
  learningPathName: string;
  name: string;
  slugName: string;
  description: string;
  imageUrl: string | null;
  learningPathInstructors: ICertificateInstructor[];
  courseBundle: CourseBundle | null;
  allVersions: IAllVersion[];
  userCertificateUrl: string | null;
  facebookDescription: string;
  facebookImage: string;
  facebookTitle: string;
  googleDescription: string;
  lineImage: string;
  receivedStatus: string | null;
};

export type FetchLearningPathDetailState = FetchLearningPathDetailResponse;

export type FetchLearningPathDetailSuccessAction = FetchLearningPathDetailState;

export type FetchLearningPathDetailErrorAction = unknown;

export type HttpFetchLearningPathDetailStatusResponse = CoreApiStatusResponse;

export type HttpFetchLearningPathDetailApiResponse = CoreApiResponse<
  FetchLearningPathDetailSuccessAction,
  HttpFetchLearningPathDetailStatusResponse
>;

export type FetchLearningPathDetailSeoRequestAction = {
  learningPathId: string;
};

export type FetchLearningPathDetailSeoResponse = {
  learningPathName: string;
  description: string;
  imageUrl: string | null;
};

export type FetchLearningPathDetailSeoState = FetchLearningPathDetailSeoResponse;

export type FetchLearningPathDetailSeoSuccessAction = FetchLearningPathDetailSeoState;

export type FetchLearningPathDetailSeoErrorAction = unknown;

export type HttpFetchLearningPathDetailSeoStatusResponse = CoreApiStatusResponse;

export type HttpFetchLearningPathDetailSeoApiResponse = CoreApiResponse<
  FetchLearningPathDetailSeoSuccessAction,
  HttpFetchLearningPathDetailSeoStatusResponse
>;

export type CreateLearningPathShareLogRequestAction = {
  learningPathId: number;
  shareType: number;
};

export type CreateLearningPathShareLogResponse = number;

export type CreateLearningPathShareLogState = CreateLearningPathShareLogResponse;

export type CreateLearningPathShareLogSuccessAction = CreateLearningPathShareLogState;

export type CreateLearningPathShareLogErrorAction = unknown;

export type HttpCreateLearningPathShareLogResponse = CoreApiStatusResponse;

export type HttpCreateLearningPathShareLogApiResponse = CoreApiResponse<
  CreateLearningPathShareLogSuccessAction,
  HttpCreateLearningPathShareLogResponse
>;

export type HttpUpdateLearningPathApiResponse = CoreApiResponse<number, CoreApiStatusResponse>;

export type ManageLearningPathReduxState = {
  moduleCertificateCreate: DefaultEventState<ModuleCertificateCreateState>;
  cachedModuleCertificateCreate: DefaultEventState<ModuleCertificateCreateState>;
  slugDropdownOptions: DefaultEventState<string[]>;
  learningPathListState: DefaultEventState<FetchLearningPathListState>;
  deleteLearningPathState: DefaultEventState<DeleteLearningPathStatusResponse>;
  mainCourseCategoryDropdownOptions: DefaultEventState<MainCourseCategoryDropdownOption[]>;
  subCourseCategoryDropdownOptions: DefaultEventState<SubCourseCategoryDropdownOption[]>;
  moduleCertificateCourseList: DefaultEventState<FetchModuleCertificateCourseListResponse>;
  moduleCertificateAllCourseList: DefaultEventState<FetchModuleCertificateCourseListResponse>;
  moduleCertificateCreateError: DefaultEventState<ModuleCertificateCreateErrorState>;
  moduleCertificatePreview: DefaultEventState<string>;
  moduleCertificateCreateSaveDraft: DefaultEventState;
  moduleCertificateSavePublish: DefaultEventState;
  instructorDropdownOptions: DefaultEventState<InstructorDropdownOption[]>;
  LearningPathStudentList: DefaultEventState<FetchLearningPathStudentListState>;
  moduleCertificateChangeStatus: DefaultEventState;
  uploadLearningPathCardState: DefaultEventState<UploadLearningPathImageResponse>;
  uploadLearningPathBannerCoverState: DefaultEventState<UploadLearningPathImageResponse>;
  uploadLearningPathLineImageState: DefaultEventState<UploadLearningPathImageResponse>;
  uploadLearningPathFacebookImageState: DefaultEventState<UploadLearningPathImageResponse>;
  learningPathSectionItemList: DefaultEventState<LearningPathEntity[]>;
  learningPathCourseBundleList: DefaultEventState<FetchLearningPathCourseBundleListResponse>;
  learningPathDetailState: DefaultEventState<FetchLearningPathDetailState>;
  learningPathDetailSeoState: DefaultEventState<FetchLearningPathDetailSeoState>;
  createLearningPathShareLogState: DefaultEventState<CreateLearningPathShareLogState>;
};
