import config from 'src/config';
import Http from 'src/helpers/httpClient';
import { convertObjectToQueryString } from 'src/helpers/utils';

const END_POINT = `${config.API_DOMAIN}/v1/categories`;

export const CATEGORY_END_POINT = {
  CATEGORIES: END_POINT,
  CATEGORY: (slug: string) => `${END_POINT}/${slug}`,
  SECTIONS: (id: number) => `${END_POINT}/${id}/sections`,
};

type CategoryQuery = {
  includeDisabled?: boolean;
  includeSubcategory?: boolean;
};

export default {
  fetchCategories(query?: CategoryQuery) {
    const apiUrl = (() => {
      if (query) {
        return `${CATEGORY_END_POINT.CATEGORIES}?${convertObjectToQueryString(query)}`;
      }
      return CATEGORY_END_POINT.CATEGORIES;
    })();
    return Http.get(apiUrl, { isPublic: true });
  },
  fetchCategory(slug: string, query?: CategoryQuery) {
    const apiUrl = `${CATEGORY_END_POINT.CATEGORY(slug)}?${convertObjectToQueryString(query!)}`;
    return Http.get(apiUrl, { isPublic: true });
  },
  fetchSections(id: number) {
    const apiUrl = CATEGORY_END_POINT.SECTIONS(id);
    return Http.get(apiUrl, { isPublic: true });
  },
};
