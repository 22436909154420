import { type } from 'os';

import { ACADEMIC_TYPE, QuestionType } from 'src/helpers/constants';
import { QuizSubmission } from 'src/types/enrollment';

import { IProduct } from './product';
import { IUser } from './user';

import { UpdateOfficialProfileFormFields } from '@components/atoms/dialog/getCertificateModal/updateOfficialProfileForm';
import { GetMyCertificates } from '@redux/user/interface';

export interface IChapter {
  id: number;
  description: string;
  course_id: number;
  video_file_name: string;
  video_content_type: string;
  video_file_size: number;
  video_updated_at: Date;
  duration: number;
  video_version: number;
  transcode_status: string;
  transcode_job_id: string;
  video_playlists: string;
  is_free: boolean;
}

export interface IChapterPreview {
  preview_video: string;
  interactive_contents?: IInteractiveContent[];
}

export interface IInteractiveContent {
  id: number;
  quiz_id?: number;
  article_id?: number;
  chapter_id?: number;
  name: string;
  display_point: number;
  is_enabled: boolean;
  skippable: boolean;
  interaction_type: InteractionType;
  content?: IArticle | IQuiz;
}

export interface ISubtitle {
  label: string;
  src: string;
}

export interface IVideo {
  id: number;
  videoUrl: string;
  // TODO: implement get video url
}

export interface IArticle {
  courseItemId: number;
  id: number;
  name: string;
  description: string;
  duration: number;
}

export interface IVideo {
  courseItemId: number;
  video_content_type: string;
  dev_must_change: string;
}

export interface IQuiz {
  id: number;
  name: string | null;
  description: string | null;
  enabled: boolean | null;
  show_answer: boolean | null;
  retest: boolean | null;
  deleted_at: Date | null;
  created_at: Date | null;
  updated_at: Date | null;
  published_at: Date | null;
  total_score: number;
  percent_ratio: number | null;
  quizSubmission?: QuizSubmission;
  validate_question_set_score: boolean;
  time_limit: number;
  time_limit_day: number | null;
  retest_times: number;
  enable_timer: boolean | null;
  quiz_type: string;
  test_type: string;
  courseItemId: number;
  questions: IQuestion[][];
  questionSetId: number;
  questionSetName: string;
  retestTime?: number;
  totalQuestion?: number;
  question_sets?: IQuestionSets[];
}

export interface IQuestionSets {
  id: number;
  name: string;
  description: string | null;
  quiz_id: number;
  created_at: Date;
  updated_at: Date;
  status: string;
  deleted_at: Date | null;
  question_sets_questions?: {
    content?: IQuestion;
  }[];
}

export interface IQuestionSet {
  id: number;
  name: string;
  description: string;
  status: string;
  question_sets_questions: {
    position?: number;
    questions: IQuestion;
  }[];
}

export interface IQuestion {
  id: number;
  name: string;
  explanation: string;
  image_url?: string;
  enabled: boolean;
  position?: number;
  score: number;
  question_type: string;
  answer_type?: string;
  show_amount_answer: boolean;
  uploadable: boolean;
  max_upload_files: number;
  narratable: boolean;
  answers: IAnswer[];
}

export interface IAnswer {
  id: number;
  description: string;
  feedback_text?: string;
  group_name?: string;
  score: number;
  is_correct?: boolean;
}

export interface IUserAnswer {
  id?: number;
  is_correct?: true;
  group_name?: string;
  answer_text?: string;
}

export interface IBundleItems {
  id: number;
  course_bundle_id: number | null;
  course_id: number | null;
  course_price: number | null;
}

export interface ICourseDetailForLearningCourse {
  id_name: string;
  name: string;
  product: {
    isCpd: boolean;
    isAcademic: boolean;
    product_type_id: IProduct['product_type_id'];
  };
  salePrices?: {
    regular?: number;
    audit?: number;
    credit?: number;
    upgrade?: number;
  };
  certificate_criteria: CertificateCriteriaStatus;
  academicEnhanceInfo?: IAcademicEnhanceInfo;
  learningProgress: number;
  isReviewed: boolean;
  reviewSkip: {
    skipTimes: number;
    latestSkipAt: Date;
  };
}

export interface ICertificateProgress {
  completedCertificate: GetMyCertificates[];
  certificateBundleProgress: GetMyCertificates[];
}

export interface CertificateCriteriaStatus {
  qualified: boolean;
  certificate: ICertificate | null;
  criteria: {
    criteria_learn_percent: number | null;
    assessment:
      | {
          quizId: number;
          quizName: string;
          criteriaScore: number;
        }[]
      | null;
  };
  isCertificateBundle: boolean;
}

export interface ICertificate {
  id: number;
  enrollment_id: number;
  cert_no: string;
  cert_url: string;
  created_at: Date;
  updated_at: Date;
}

export interface ILearningPageCurriculum {
  parts?: ILearningPagePart[];
  course?: ICourseDetailForLearningCourse;
  content?: IQuiz | IArticle | IVideo; // TODO: implement other content type later
  enrollment?: LearningEnrollment;
}

export type LearningEnrollment = {
  id: number;
  academicType: ACADEMIC_TYPE;
  examExpiryTime: Date;
  interactiveContentSubmissions: IInteractiveContentSubmissions[];
};

export interface ILearningPagePart {
  id: number;
  name: string;
  courseItems: ILearningPageCourseItem[];
}

export interface ILearningPageCourseItem {
  id: number;
  chapterId?: number;
  quizId?: number;
  status: LearningPageCourseItemStatus;
  name: string;
  contentType: CourseItemContentType;
  isCompulsoryAbove: boolean;
  isCompulsoryBelow: boolean;
  isQuizCriteria?: boolean;
  metadata: IVideoMetadata | IArticleMetadata | IQuizMetadata;
  attachments?: IAttachments[];
}

export type ILearningPageCourseItemContent = IVideoContent | any; // TODO: IQuizContent and IArticleContent should be added

export interface IVideoContent extends IChapter {
  interactive_contents?: IInteractiveContent[];
  video_url?: string;
  subtitles?: ISubtitle[];
  seekTime: number;
}

export interface ICourseItemMetadata {}

export interface IVideoMetadata extends ICourseItemMetadata {
  duration: number;
  isComingSoon: boolean;
  publishAt: Date;
}

export interface IAttachments {
  attachmentFileName?: string;
  attachmentUrl?: string;
}

export interface IArticleMetadata extends ICourseItemMetadata {
  duration: number;
}

export interface IQuizMetadata extends ICourseItemMetadata {
  assessmentType: AssessmentType;
  assignmentStatus?: AssignmentStatus;
  assignmentExpiredAt?: Date;
}

export interface QuizSubmissionPayload {
  quizSubmissionId: number;
  quizSubmission: {
    isQuizLeave: boolean;
    questionSetId: number;
    courseItemId: number;
    userAnswers: UserAnswers[];
  };
}

export interface UserAnswers {
  questionId: number;
  questionType: string;
  answerIds: any[];
  answerIndex: number;
  uploadFiles: UploadFile[];
}

export interface UploadFile {
  preview: string;
  file: File;
}

export interface StartInteractionAnswerPayload {
  article_id?: number;
  quiz_id?: number;
  question_set_id?: number;
  started_at: string;
  type: InteractionType;
  total_score?: number;
}

export interface SubmitInteractionAnswerPayload {
  article_id?: number;
  quiz_id?: number;
  question_set_id?: number;
  finished_at: string;
  type: InteractionType;
  total_score?: number;
  answers?: {
    question_id?: number;
    user_answer_ids?: number[];
    user_answer_id?: number;
    user_answer_text?: string;
    user_answers?: {
      group_name: string;
      answer_text: string;
    }[];
  }[];
}

export interface CloseInteractionAnswerPayload {
  article_id?: number;
  quiz_id?: number;
  question_set_id?: number;
  finished_at: string;
  type: InteractionType;
}

export interface AnswerResult {
  id: number;
  is_correct: boolean;
}

export interface SubmitInteractionAnswerResponse {
  id: number;
  article_id: number;
  quiz_id: number;
  question_set_id: number;
  score: number;
  total_score: number;
  percent_ratio?: number;
  total_percent_ratio?: number;
  total_question: number;
  time_spent: number;
  status: SubmitInteractionAnswerStatus;
  detail: {
    content: SubmitInteractionAnswerDetail[];
  };
  published_at: string;
  started_at: string;
  finished_at: string;
  expired_at?: string;
  created_at: string;
  updated_at: string;
  deleted_at?: string;
  enrollment_id: number;
}

export interface SubmitInteractionAnswerDetail {
  name: string;
  type: InteractionType;
  answers: IAnswer[];
  image_url?: string;
  max_score: number;
  explanation?: string;
  question_id: number;
  result_score: number;
  user_answer: {
    id: number;
    is_correct: true;
  };
  user_answers: {
    id?: number;
    is_correct?: true;
    group_name?: string;
    answer_text?: string;
  }[];
}

export interface IWatermarkInfo {
  firstname?: string;
  lastname?: string;
  email?: string;
  ip?: string;
  datetime?: string;
}

export type CreateOrUpdateFinishedLogPayload = {
  courseItemId: number;
  timeSpent?: number;
  finished?: boolean;
  enrollmentId: number;
  seekTime?: number;
};

export type CreateOrUpdateFinishedLogResponse = {
  courseItemId: number;
  courseItemStatus: LearningPageCourseItemStatus;
};

export type GetCertificateProgressionResponse = {
  completedCertificate: GetMyCertificates[];
  certificateBundleProgress: GetMyCertificates[];
};

export type CreateCertificatePayload = {
  course_id_name: string;
  enrollment_id: number;
  official_info: UpdateOfficialProfileFormFields;
};

export interface IInteractiveContentSubmissions {
  id: number;
  article_id?: number | null;
  quiz_id?: number | null;
  question_set_id: number | null;
  score: number | null;
  total_score: number | null;
  percent_ratio?: number;
  total_percent_ratio?: number;
  total_question: number;
  time_spent: number;
  status: string;
  detail?: IArticleDetail[] | IQuizDetail;
  published_at?: Date;
  started_at: Date;
  finished_at?: Date;
  expired_at?: Date;
  created_at: Date;
  updated_at: Date;
  deleted_at?: null;
  enrollment_id: number;
}

export interface IArticleDetail {
  name: string;
  type: string;
  image_url: null;
  max_score: null;
  explanation: null;
  question_id: number;
  result_score: null;
  user_answer_text: string;
}

export interface IQuizDetail {
  name?: string;
  type?: string;
  article_id?: number;
  description?: string;
  content?: Content[];
}

export interface Content {
  name: string;
  type: string;
  answers: IAnswer[];
  image_url: null;
  max_score: number;
  explanation: null | string;
  question_id: number;
  result_score: number;
  user_answers?: IAnswer[];
  user_answer?: IAnswer;
}

export interface IFormAnswer {
  questionId: number;
  questionType: QuestionType;
  answerIds: number[];
  answerIndex: number | null;
  answerText?: string;
  uploadFiles: IFile[];
}

export interface IFile {
  path: string;
  preview: string;
  file: File | Blob;
}

export enum SubmitInteractionAnswerStatus {
  COMPLETED = 'completed',
  PENDING = 'pending',
}

export enum LearningPageCourseItemStatus {
  COMPLETED = 'completed',
  PROGRESS = 'progress',
  PENDING = 'pending',
}

export enum CourseItemContentType {
  VIDEO = 'video',
  QUIZ = 'quiz',
  ARTICLE = 'article',
}

export enum AssignmentStatus {
  UNSUBMIT = 'unsubmit',
  STAY_IN_TIME = 'stayInTime',
  IN_PROGRESS = 'inprogress',
  PENDING = 'pending',
  COMPLETED = 'completed',
  EXPIRED = 'expired',
  CANCELLED = 'cancelled',
}

export enum AssessmentType {
  REGULAR = 'regular',
  ASSIGNMENT = 'assignment',
  EXAMINATION = 'examination',
}

export enum InteractionType {
  ARTICLE = 'article',
  MULTIPLE_CHOICES = 'multiple_choices',
  MULTIPLE_CHOOSE = 'multiple_choose',
  FILL_IN_THE_BLANK = 'fill_in_the_blank',
  ESSAY = 'essay',
}

export type VideoSetting = {
  volume?: number;
  playbackRate?: number;
  isMuted?: boolean;
  quality?: string;
  textTrackLabel?: string | null;
};

export interface IAcademicEnhanceInfo {
  type: AcademicEnhanceTypeEnum;
  price?: number;
  isNeedCreditPlusAlert?: boolean;
  examExpiredAt?: Date;
}

export enum AcademicEnhanceTypeEnum {
  UPGRADE = 'upgrade',
  CREDIT_PLUS = 'credt-plus',
  RE_GRADE = 're-grade',
  CREDIT_COLLECTED = 'credit-collected',
  GRADE_PENDING = 'grade-pending',
}

export interface IPagination<T> {
  items: T[];
  count: number;
  hasMore: boolean;
}

export type IReviewList = IPagination<IReview>;

export interface IReview {
  userId: number;
  userAvatar: string;
  rating: number;
  reviewerName: string;
  comment?: string;
  updatedAt: Date;
}

export interface IReviewSummary {
  averageRating: number;
  count: number;
  ratings: {
    rating: number;
    totalReviewer: number;
  }[];
}

export interface IReview {
  userId: number;
  userAvatar: string;
  rating: number;
  reviewerName: string;
  comment?: string;
  updatedAt: Date;
}

export interface IReviewSummary {
  averageRating: number;
  count: number;
  ratings: {
    rating: number;
    totalReviewer: number;
  }[];
}

// TODO: interface discussions
export type IDiscussionList = IPagination<IDiscussion>;

export interface IDiscussion {
  id: number;
  title: string;
  displayName: string;
  userAvatar: string;
  isAuthor: boolean;
  userId: number;
  createdAt: Date;
  discussion_replies: {
    id: number;
    content: string;
    userId: number;
    displayName: string;
    userAvatar: string;
    isAuthor: boolean;
    createdAt: Date;
  }[];
}

export interface DiscussionReply {
  id: number;
  user_id: number;
  discussion_id: number;
  content: string;
  enable: boolean;
  created_at: Date;
  updated_at: Date;
  hide: boolean;
  user: IUser & {
    displayName: string;
    userAvatar: string;
    isAuthor: boolean;
  };
}
