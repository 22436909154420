import Router from 'next/router';
import { KeyboardEvent, SyntheticEvent } from 'react';

import dayjs from 'dayjs';
import 'dayjs/locale/th';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import duration from 'dayjs/plugin/duration';
import utc from 'dayjs/plugin/utc';
import { useTranslation } from 'next-i18next';
import sanitizeHtml from 'sanitize-html';
import config from 'src/config';
import {
  IInstructorsInfo,
  ICourse,
  CPDLicenseTypeEnum,
  LicenseRenewalTypeEnum,
  ApplicantTypeEnum,
  CPDRegulatorTypeEnum,
  ICPDLicense,
} from 'src/interfaces';
import { ICPDEnrollment } from 'src/interfaces/bundle';
import Cookies from 'universal-cookie';

import {
  ACADEMIC_TYPE,
  ALLOW_SANITIZE_ATTRIBUTES,
  ALLOW_SANITIZE_TAGS,
  CODE_MOBILE_PHONE_THAILAND,
  CookiePolicyState,
  CpdEmailValidateResult,
  EmailValidateResultCode,
  ProductTypeId,
  ROUTES,
} from '@helpers/constants';
import {
  AcademicEnhanceTypeEnum,
  CourseItemContentType,
  CreateOrUpdateFinishedLogPayload,
  IAcademicEnhanceInfo,
  ICourseDetailForLearningCourse,
  ILearningPageCourseItem,
  ILearningPageCurriculum,
  ILearningPagePart,
  IVideoMetadata,
  LearningPageCourseItemStatus,
} from '@interfaces/learning';
import { IQuiz } from '@interfaces/quiz';
import { IUserOfficialInfo } from '@interfaces/user';
import { ROUTES as ROUTES_CONSTANT } from '@common/constant/routeConstant';

import { camelCase } from 'lodash';

dayjs.extend(utc);
dayjs.extend(buddhistEra);
dayjs.extend(duration);
dayjs.extend(customParseFormat);
dayjs.locale('th');

interface ConfigKeyPress {
  toUpperCase?: boolean;
  includeDot?: boolean;
  includeHyphen?: boolean;
}

const MILLISECOND = 1000;

export const convertSecondToMs = (second: number) => second * MILLISECOND;

export const formatPercent = (number: number) => `${number.toFixed(2)}%`;

export const numberWithCommas = (number: number) => number?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const getDaysLeft = (date: Date) => {
  const targetDate = dayjs(date).startOf('day');
  const currentDate = dayjs().startOf('day');
  return targetDate.diff(currentDate, 'day');
};

export const isExpired = (date: Date) => {
  const currentDate = dayjs();
  const expiryDate = dayjs(date);
  return expiryDate.isBefore(currentDate, 'second');
};

export const dateFormatThai = (date: Date) => dayjs(date).format('DD/MM/BBBB');

export const toChristianDate = (date: Date | dayjs.Dayjs): Date =>
  dayjs(date)
    .subtract(543, 'year')
    .toDate();

export const toFormatCPDEnrollmentStringDate = (str: string | null) =>
  str ? dayjs(str, 'DD/MM/BBBB').format('DD / MM / BBBB') : '';

export const startOfMonth = (date: Date): Date =>
  dayjs(date)
    .startOf('month')
    .toDate();

export const endOfMonth = (date: Date): Date =>
  dayjs(date)
    .endOf('month')
    .toDate();

export const convertDurationTime = (duration: number) => {
  const allMin = Math.floor(duration / 60);
  const hour = Math.floor(allMin / 60);
  const min = allMin % 60;
  const second = duration % 60;

  const simpleFormat = {
    minAndSecond: () => {
      const m = min.toLocaleString('en-US', { minimumIntegerDigits: 2 });
      const s = second.toLocaleString('en-US', { minimumIntegerDigits: 2 });

      return `${m}:${s}`;
    },

    flexibleTime: () => {
      const h = hour.toLocaleString('en-US', { minimumIntegerDigits: 2 });
      const m = min.toLocaleString('en-US', { minimumIntegerDigits: 2 });
      const s = second.toLocaleString('en-US', { minimumIntegerDigits: 2 });

      if (h !== '00') return `${h}:${m}:${s}`;

      return `${m}:${s}`;
    },
  };

  return {
    allMin,
    hour,
    min,
    second,
    simpleFormat,
  };
};

export const convertDurationTimeByFormat = (duration: number, format: string) =>
  dayjs.duration(duration, 'seconds').format(format);

export const convertTimeVideoString = (second: number, t: any) => {
  if (second) {
    const { hour, min } = convertDurationTime(second);
    if (hour > 0) {
      return `${numberWithCommas(hour)} ${t('home.course_card_hours')} ${min} ${t('home.course_card_minute')}`;
    }
    return `${min} ${t('home.course_card_minute')}`;
  }
  return `0 ${t('home.course_card_minute')}`;
};

export const plusDayFromCurrent = (amountDay: number) => {
  const result = dayjs()
    .add(amountDay, 'day')
    .toDate();
  return result;
};

export const setUTMCookie = (query: any) => {
  const { utm_source, utm_medium, utm_campaign, utm_term, utm_content } = query;
  const expiresDayUTM = plusDayFromCurrent(60);
  const cookies = new Cookies();
  if (utm_source || utm_medium || utm_campaign || utm_term || utm_content) {
    cookies.remove('utm_source');
    cookies.remove('utm_medium');
    cookies.remove('utm_campaign');
    cookies.remove('utm_term');
    cookies.remove('utm_content');
  }
  if (utm_source) {
    cookies.set('utm_source', utm_source, { expires: expiresDayUTM, path: '/' });
  }
  if (utm_medium) {
    cookies.set('utm_medium', utm_medium, { expires: expiresDayUTM, path: '/' });
  }
  if (utm_campaign) {
    cookies.set('utm_campaign', utm_campaign, { expires: expiresDayUTM, path: '/' });
  }
  if (utm_term) {
    cookies.set('utm_term', utm_term, { expires: expiresDayUTM, path: '/' });
  }
  if (utm_content) {
    cookies.set('utm_content', utm_content, { expires: expiresDayUTM, path: '/' });
  }
};

export const convertObjectToQueryString = (query: {}) => new URLSearchParams(query).toString();

export const getSlugFromUrl = (url: string) => {
  const matchedSlug = url.match(/\[(\w+)\]/);
  const slug = matchedSlug && matchedSlug[1];
  return slug;
};

export const getAllSlugFromUrl = (url: string) => {
  const matchedSlug = url.match(/\[(\w+)\]/g);
  const slugs = matchedSlug?.length && matchedSlug.map((item) => item.replace(/\[*\]*/g, ''));
  return slugs;
};

export const updateQuery = (query: {}, shallow = true) => {
  const slugs = getAllSlugFromUrl(Router.route);
  let slugQuery: {} | null = null;
  if (slugs) {
    slugQuery = {};
    slugs.forEach((slug) => Object.assign(slugQuery as Object, { [slug]: Router.query[slug] }));
  }
  // work around for page that has slug
  if (slugs) {
    Router.push(
      {
        pathname: Router.route,
        query: slugQuery,
      },
      { query: convertObjectToQueryString(query) },
      { shallow },
    );
  } else {
    Router.push({ ...Router }, { query: convertObjectToQueryString(query) }, { shallow });
  }
};

export const getAssetUrl = (path: string) => `${config.IMAGE_CDN_HOST}/assets/${path}`;

export const getImageUrl = (path: string) => {
  if (path?.includes('http')) return path;
  return `${config.IMAGE_CDN_HOST}/${path}`;
};

export const validateEmail = (mail: string) => {
  const validRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  if (mail.match(validRegex)) {
    return true;
  }
  return false;
};

export const getFullnameInstructor = (instructor: IInstructorsInfo) => {
  if (!instructor?.firstname && !instructor?.lastname) return '';
  return `${instructor?.firstname || ''} ${instructor?.lastname || ''}`;
};

export const getDomain = () => {
  const splitDomain = window.location.hostname.split('.');
  if (splitDomain.length > 2) {
    return `.${splitDomain[1]}.${splitDomain[2]}`;
  }
  return splitDomain[0];
};

export const setAcceptCookiePolicy = (value: boolean) => {
  const cookies = new Cookies();
  cookies.set('cookie_policy_accepted', value, {
    path: '/',
    domain: getDomain(),
    expires: value ? plusDayFromCurrent(365) : plusDayFromCurrent(1),
  });
};

export const getCookiePolicyAcceptedState = () => {
  const cookies = new Cookies();
  const cookiePolicyAccepted = cookies.get('cookie_policy_accepted');
  switch (cookiePolicyAccepted) {
    case 'true':
      return CookiePolicyState.Accept;
    case 'false':
      return CookiePolicyState.NotAccept;
    default:
      return CookiePolicyState.Pending;
  }
};

/** @deprecated use getCourseUrlV2 instaed */
export const getCourseUrl = (course: ICourse) => {
  const isCpd = course.product?.isCpd;
  if (isCpd) {
    return `/cpd/courses/${course.id_name}`;
  }
  if (course.bundle_items) {
    return ROUTES_CONSTANT.BUNDLE.VIEW_DETAIL(course.id_name);
  }
  return ROUTES_CONSTANT.COURSE.VIEW_DETAIL(course.id_name);
};

export const getCourseUrlV2 = (course: ICourse | ICourseDetailForLearningCourse) => {
  const isCpd = course.product?.isCpd;
  const isBundle = course.product.product_type_id === ProductTypeId.BUNDLE;
  if (isCpd) {
    return `/cpd/courses/${course.id_name}`;
  }
  if (isBundle) {
    return ROUTES_CONSTANT.BUNDLE.VIEW_DETAIL(course.id_name);
  }
  return ROUTES_CONSTANT.COURSE.VIEW_DETAIL(course.id_name);
};

export const getBundleUrl = (idName: string, isCpdTheme: boolean) => {
  if (isCpdTheme) {
    return `/cpd/bundles/${idName}`;
  }
  return ROUTES_CONSTANT.BUNDLE.VIEW_DETAIL(idName);
};

export const getCourseTypeCpd = (course: ICourse) => {
  let type = '';
  if (course.regulator === CPDRegulatorTypeEnum.OIC) {
    for (const license of course.licenseTypes) {
      type += `นายหน้า/ตัวแทน ${getLicenseTypes(license)} `;
    }
  } else {
    type = 'ผู้แนะนําการลงทุน/ผู้วางแผนการลงทุน/นักวิเคราะห์การลงทุน';
  }
  return type;
};

export const getLicenseCPDPath = (license: CPDLicenseTypeEnum) => {
  if (license === CPDLicenseTypeEnum.LIFE) {
    return `${ROUTES.CPD_LANDING}/oic-life`;
  }
  if (license === CPDLicenseTypeEnum.NON_LIFE) {
    return `${ROUTES.CPD_LANDING}/oic-non-life`;
  }
  return `${ROUTES.CPD_LANDING}/tsi`;
};

export const getLicenseTypes = (license: CPDLicenseTypeEnum) => {
  const { t } = useTranslation('common');
  if (license === CPDLicenseTypeEnum.LIFE) return t('cpd_course.license_types.oic.life');
  if (license === CPDLicenseTypeEnum.NON_LIFE) return t('cpd_course.license_types.oic.non_life');
  if (license === CPDLicenseTypeEnum.INVESTMENT) return t('cpd_course.license_types.tsi.investment');
};

export const allowOnlyNumber = (value: string) => value.replace(/[^0-9]/g, '');

export const allowOnlyThai = (value: string) => value.replace(/[A-Za-z0-9$&+,:;=?@#|'<>.^*()%!-]+/, '');

export const validateAllowOnlyThai = (value: string) => /[A-Za-z0-9$&+,:;=?@#|'<>.^*()%!-]+/.test(value);

export const validateNotAllowSpace = (value: string) => !/^\S*$/g.test(value);

export const getCpdLicenseTitleKey = (
  regulator: CPDRegulatorTypeEnum,
  licenseRenewal: LicenseRenewalTypeEnum,
  applicantType: ApplicantTypeEnum,
  licenseType: CPDLicenseTypeEnum,
): string => {
  const keyPrefix = 'cpd_enrollment.license_information';
  if (regulator === CPDRegulatorTypeEnum.TSI) return `${keyPrefix}.tsi.title`.toLowerCase();
  if (regulator !== CPDRegulatorTypeEnum.OIC) return '';

  if (licenseRenewal === LicenseRenewalTypeEnum.FourthPlusRenewal)
    return `${keyPrefix}.${regulator}_${licenseRenewal}_${applicantType}.title`.toLowerCase();
  return `${keyPrefix}.${regulator}_${applicantType}_${licenseType}.title`.toLowerCase();
};

export const getCpdBundleLicenseTitleKey = (regulator: CPDRegulatorTypeEnum, applicantLicenseType: string): string => {
  const keyPrefix = 'cpd_enrollment.license_information';
  if (regulator === CPDRegulatorTypeEnum.TSI) return `${keyPrefix}.tsi.title`.toLowerCase();
  return `${keyPrefix}.oic_bundle_${applicantLicenseType}.title`.toLowerCase();
};

export const getLicenseFieldNames = (licenseName: string) => ({
  licenseNoFieldName: `oic${licenseName}LicenseNo`,
  licenseStartDateFieldName: `oic${licenseName}StartDate`,
  licenseEndDateFieldName: `oic${licenseName}EndDate`,
});

export const getBundleLicenseFieldNames = (applicantType: string, licenseName: string) => ({
  licenseNoFieldName: `oic${applicantType}${licenseName}LicenseNo`,
  licenseStartDateFieldName: `oic${applicantType}${licenseName}StartDate`,
  licenseEndDateFieldName: `oic${applicantType}${licenseName}EndDate`,
});

export const toCapitalized = (word: string) => {
  const toLowerWord = word.toLowerCase();
  return toLowerWord.charAt(0).toUpperCase() + toLowerWord.slice(1);
};

export const getLicenseTypeName = (courseLicenseType: CPDLicenseTypeEnum) =>
  courseLicenseType === CPDLicenseTypeEnum.LIFE ? 'Life' : 'NonLife';

export const getCPDLicensesByRegulator = (
  cpdEnrollments: ICPDEnrollment[],
  regulator: CPDRegulatorTypeEnum,
): ICPDLicense[] => {
  let licenses: ICPDLicense[] = [];
  for (const cpdEnrollment of cpdEnrollments) {
    licenses = licenses.concat(cpdEnrollment.licenses);
  }

  const avalibleApplicantTypes =
    regulator === CPDRegulatorTypeEnum.OIC
      ? [ApplicantTypeEnum.AGENT, ApplicantTypeEnum.BROKER]
      : [ApplicantTypeEnum.ADVISOR, ApplicantTypeEnum.PLANNER, ApplicantTypeEnum.ANALYST];

  licenses = licenses.filter(
    (license, index) => licenses.indexOf(license) === index && avalibleApplicantTypes.includes(license.applicantType),
  );

  return licenses;
};

export const isSameDay = (date1: Date, date2: Date): boolean => {
  if (!date1 || !date2) return false;
  return dayjs(date1).isSame(date2, 'day');
};

/** @deprecated use displayCitizenId instead */
export const formatCitizenId = (value?: string) => {
  if (!value) return '';

  const citizenId = value ? value.substring(0, 13) : '';

  return `${citizenId.substring(0, 1)}-${citizenId.substring(1, 5)}-${citizenId.substring(5, 10)}-${citizenId.substring(
    10,
    12,
  )}-${citizenId.substring(12, 13)}`;
};

export const removeSpaceFromText = (text: string): string => {
  if (!text) return '';

  return text.replace(/\s/g, '');
};

export const checkCpdEmail = (code: number): CpdEmailValidateResult => {
  if ([EmailValidateResultCode.AcceptAll, EmailValidateResultCode.Deliverable].includes(code)) {
    return CpdEmailValidateResult.Pass;
  }
  if (
    [
      EmailValidateResultCode.SpamTrap,
      EmailValidateResultCode.Disposable,
      EmailValidateResultCode.Invalid,
      EmailValidateResultCode.Unknown,
      EmailValidateResultCode.Role,
    ].includes(code)
  ) {
    return CpdEmailValidateResult.Uncertain;
  }
  return CpdEmailValidateResult.NotPass;
};

export const scrollToElement = (elementID: string) => {
  const element = document.getElementById(elementID);
  if (element) {
    element.scrollIntoView();
  }
};

export const getSortingByRegulatorType = (course: ICourse[]) => {
  const oic = course?.filter((item) => item.regulator === CPDRegulatorTypeEnum.OIC);
  const tsi = course?.filter((item) => item.regulator === CPDRegulatorTypeEnum.TSI);
  if (oic || tsi) {
    return [...oic, ...tsi];
  }
  return null;
};

export const isPastMonth = (date: Date): boolean => {
  const now = dayjs();
  const nowMonth = now.get('month');
  const nowYear = now.get('year');

  const dataMonth = dayjs(date).get('month');
  const dataYear = dayjs(date).get('year');

  const nowDate = dayjs(`${nowYear}-${nowMonth}-01`);
  const dataDate = dayjs(`${dataYear}-${dataMonth}-01`);

  return nowDate <= dataDate;
};

export const encryptOrDecryptMessage = (message: string, isEncrypt: boolean): string => {
  if (!message) return '';
  return isEncrypt ? Buffer.from(message).toString('base64') : Buffer.from(message, 'base64').toString('ascii');
};

export const swrImmutableOptions = { revalidateIfStale: false, revalidateOnFocus: false, revalidateOnReconnect: false };

export const isEmptyObject = (obj: Object) => JSON.stringify(obj) === '{}';

export const secToTimeString = (duration: number): string => {
  // Hours, minutes and seconds
  const hrs = ~~(duration / 3600);
  const mins = ~~((duration % 3600) / 60);
  const secs = ~~duration % 60;

  // Output like "1:01" or "4:03:59" or "123:03:59"
  let ret = '';

  if (hrs > 0) {
    ret += `${hrs}:${mins < 10 ? '0' : ''}`;
  }

  ret += `${mins}:${secs < 10 ? '0' : ''}`;
  ret += `${secs}`;

  return ret;
};

export const sanitize = (dirtyHtml: string, options?: sanitizeHtml.IOptions) => ({
  __html: sanitizeHtml(dirtyHtml, {
    allowedTags: ALLOW_SANITIZE_TAGS,
    allowedAttributes: ALLOW_SANITIZE_ATTRIBUTES,
    allowedIframeHostnames: ['www.youtube.com'],
    ...(options || {}),
  }),
});

export const percentify = (value: number, total: number) => {
  if (total === 0) return 0;
  const percent = (value / total) * 100;
  if (percent > 100) return -1;

  return +percent.toFixed(2);
};

export const measureText = (text: string, font?: string) => {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  if (!context) return 0;

  context.font = font || getComputedStyle(document.body).font;

  return context.measureText(text).width;
};

export const convertSecondToMinute = (seconds: number) => Math.floor(seconds / 60);

export const genCharArray = (charFirst = 'A', charLast = 'Z') => {
  const listGenerateChar = [];
  let charStart = charFirst.charCodeAt(0);
  const charEnd = charLast.charCodeAt(0);

  for (charStart; charStart <= charEnd; ++charStart) {
    listGenerateChar.push(String.fromCharCode(charStart));
  }
  return listGenerateChar;
};

export const setTimeDuration = (duration: number, cb: () => void) => setTimeout(cb, convertSecondToMs(duration));

export const durationToTimeMinute = (duration: number) => {
  const minutes = Math.floor((duration % (60 * 60 * 1000)) / (60 * 1000))
    .toString()
    .padStart(2, '0');
  const seconds = Math.floor((duration % (60 * 1000)) / 1000)
    .toString()
    .padStart(2, '0');
  return `${minutes}:${seconds}`;
};

export const durationToTime = (duration: number) => {
  const hours = Math.floor(duration / (60 * 60 * 1000))
    .toString()
    .padStart(2, '0');
  const minutes = Math.floor((duration % (60 * 60 * 1000)) / (60 * 1000))
    .toString()
    .padStart(2, '0');
  const seconds = Math.floor((duration % (60 * 1000)) / 1000)
    .toString()
    .padStart(2, '0');
  return `${hours}:${minutes}:${seconds}`;
};

/**
 * @deprecated should refactor to receive parameter snake-case
 */
// TODO: replace profile parameter with own parameter type
export const getOfficialFullName = (profile: IUserOfficialInfo | undefined | null) => {
  const isThaiLang = (name: string | null) => {
    if (!name) return false;
    const patternTh = /^[ก-๏\s]+$/;
    return patternTh.test(name);
  };

  if (!profile) return null;
  const { official_firstname, official_lastname, official_prefix_th } = profile;
  if (!official_firstname || !official_lastname) return null;

  if (!official_prefix_th) return `${official_firstname} ${official_lastname}`;
  if (isThaiLang(official_prefix_th) && isThaiLang(official_firstname))
    return `${official_prefix_th}${official_firstname} ${official_lastname}`;
  return `${official_prefix_th} ${official_firstname} ${official_lastname}`;
};
export interface IGetCreateOrUpdateFinishedLogPayload {
  learningCourse: ILearningPageCurriculum;
  isFirstAccess: boolean;
  isUpdate?: boolean;
  finished?: boolean;
  seekTime?: number;
  timeSpent?: number;
}

export const getCreateOrUpdateFinishedLogPayload = (
  args: IGetCreateOrUpdateFinishedLogPayload,
): CreateOrUpdateFinishedLogPayload | null => {
  const { learningCourse, isFirstAccess, isUpdate, finished, seekTime, timeSpent } = args;
  const courseIdName = learningCourse?.course?.id_name;
  const courseItemList = learningCourse?.parts?.flatMap((item) => item.courseItems);
  const { content } = learningCourse;
  const { enrollment } = learningCourse;
  const currentCourseItem = courseItemList?.find((item) => item.id === content?.courseItemId);

  if (!courseIdName || !currentCourseItem || !enrollment) return null;
  const courseItemStatus = currentCourseItem.status;
  const { contentType } = currentCourseItem;
  const courseItemId = currentCourseItem.id;
  const enrollmentId = enrollment.id;
  if (isFirstAccess && contentType === CourseItemContentType.QUIZ) {
    return null;
  }

  if (contentType === CourseItemContentType.ARTICLE) {
    return {
      courseItemId,
      enrollmentId,
      finished: true,
    };
  }
  if (contentType === CourseItemContentType.QUIZ) {
    return {
      courseItemId,
      enrollmentId,
      finished,
    };
  }
  return {
    courseItemId,
    enrollmentId,
    finished,
    seekTime,
    timeSpent,
  };
};
export const instanceOfQuiz = (object: any): object is IQuiz => 'quiz_type' in object;

export const getIsCompulsoryLockCourse = (
  parts: ILearningPagePart[],
  courseItem: ILearningPageCourseItem,
  academicType: ACADEMIC_TYPE,
  academicEnhanceInfo?: IAcademicEnhanceInfo,
): boolean => {
  if (!parts) return false;

  const allCourseItems = parts?.flatMap((part) => part.courseItems);
  const courseItemIndex = allCourseItems?.findIndex((item) => item.id === courseItem.id);
  const aboveCourseItems = allCourseItems?.slice(0, courseItemIndex);

  if (courseItem.isCompulsoryAbove) {
    const isAllCompleted = aboveCourseItems.every(
      (item) =>
        item.status === LearningPageCourseItemStatus.COMPLETED ||
        getIsAcademicLock(item.contentType, academicEnhanceInfo),
    );
    if (!isAllCompleted) {
      return true;
    }
  }

  const isCompulsoryLock = aboveCourseItems.some(
    (focusCourseItem) =>
      focusCourseItem.isCompulsoryBelow &&
      focusCourseItem.status !== LearningPageCourseItemStatus.COMPLETED &&
      !getIsAcademicLock(focusCourseItem.contentType, academicEnhanceInfo),
  );

  return isCompulsoryLock;
};

export const convertHTMLToString = (questionName: string): string => questionName.replace(/(<([^>]+)>)/gi, '');
export const getIsAcademicLock = (contentType: CourseItemContentType, academicEnhanceInfo?: IAcademicEnhanceInfo) => {
  if (!academicEnhanceInfo) return false;

  const isUpgrade = academicEnhanceInfo.type === AcademicEnhanceTypeEnum.UPGRADE;
  const isCreditPlus = academicEnhanceInfo.type === AcademicEnhanceTypeEnum.CREDIT_PLUS;
  return (isUpgrade || isCreditPlus) && contentType === CourseItemContentType.QUIZ;
};

export const validateUrlImage = (path: string) => {
  const stringLowerCase = path.toLocaleLowerCase();
  return (
    stringLowerCase.indexOf('.jpg') > -1 ||
    stringLowerCase.indexOf('.jpeg') > -1 ||
    stringLowerCase.indexOf('.png') > -1
  );
};

export const getIsComingSoon = (courseItem: ILearningPageCourseItem): boolean => {
  const { contentType } = courseItem;
  if (contentType === CourseItemContentType.VIDEO) {
    const metadata = courseItem.metadata as IVideoMetadata;
    return metadata.isComingSoon;
  }
  return false;
};

export const handleKeyPressTh = (
  event: React.KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>,
  config?: ConfigKeyPress,
) => {
  const keyCode = event.keyCode || event.which;
  const keyValue = String.fromCharCode(keyCode);

  const patterns: { [key: string]: RegExp } = {
    default: /^[\u0E00-\u0E7F\s]+$/,
    includeDotOrHyphen: /^[\u0E00-\u0E7F\s.-]+$/,
    includeDot: /^[\u0E00-\u0E7F\s.]+$/,
    includeHyphen: /^[\u0E00-\u0E7F\s-]+$/,
  };

  let pattern = patterns.default;

  if (config?.includeDot && config?.includeHyphen) {
    pattern = patterns.includeDotOrHyphen;
  } else if (config?.includeDot) {
    pattern = patterns.includeDot;
  } else if (config?.includeHyphen) {
    pattern = patterns.includeHyphen;
  }

  if (!pattern.test(keyValue)) {
    event.preventDefault();
  }
};

export const handleKeyPressEn = (
  event: React.KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>,
  config?: ConfigKeyPress,
) => {
  const keyCode = event.keyCode || event.which;
  const keyValue = String.fromCharCode(keyCode);

  const patterns: { [key: string]: RegExp } = {
    default: /^[A-Za-z\s]+$/,
    includeDotOrHyphen: /^[A-Za-z\s.-]+$/,
    includeDot: /^[A-Za-z\s.]+$/,
    includeHyphen: /^[A-Za-z\s-]+$/,
  };

  let pattern = patterns.default;

  if (config?.includeDot && config?.includeHyphen) {
    pattern = patterns.includeDotOrHyphen;
  } else if (config?.includeDot) {
    pattern = patterns.includeDot;
  } else if (config?.includeHyphen) {
    pattern = patterns.includeHyphen;
  }

  if (!pattern.test(keyValue)) {
    event.preventDefault();
  }
};

export const handlePasteTh = (
  event: React.ClipboardEvent<HTMLInputElement | HTMLTextAreaElement>,
  config?: ConfigKeyPress,
) => {
  const { clipboardData } = event;
  const pastedText = clipboardData.getData('text');

  const patterns: { [key: string]: RegExp } = {
    default: /^[\u0E00-\u0E7F\s]+$/,
    includeDotOrHyphen: /^[\u0E00-\u0E7F\s.-]+$/,
    includeDot: /^[\u0E00-\u0E7F\s.]+$/,
    includeHyphen: /^[\u0E00-\u0E7F\s-]+$/,
  };

  let pattern = patterns.default;

  if (config?.includeDot && config?.includeHyphen) {
    pattern = patterns.includeDotOrHyphen;
  } else if (config?.includeDot) {
    pattern = patterns.includeDot;
  } else if (config?.includeHyphen) {
    pattern = patterns.includeHyphen;
  }

  if (!pattern.test(pastedText)) {
    event.preventDefault();
  }
};

export const handlePasteEn = (
  event: React.ClipboardEvent<HTMLInputElement | HTMLTextAreaElement>,
  config?: ConfigKeyPress,
) => {
  const { clipboardData } = event;
  const pastedText = clipboardData.getData('text');

  const patterns: { [key: string]: RegExp } = {
    default: /^[A-Za-z\s]+$/,
    includeDotOrHyphen: /^[A-Za-z\s.-]+$/,
    includeDot: /^[A-Za-z\s.]+$/,
    includeHyphen: /^[A-Za-z\s-]+$/,
  };

  let pattern = patterns.default;

  if (config?.includeDot && config?.includeHyphen) {
    pattern = patterns.includeDotOrHyphen;
  } else if (config?.includeDot) {
    pattern = patterns.includeDot;
  } else if (config?.includeHyphen) {
    pattern = patterns.includeHyphen;
  }

  if (!pattern.test(pastedText)) {
    event.preventDefault();
  }
};

export const handleKeyUp = (
  event: React.KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>,
  config?: ConfigKeyPress,
) => {
  if (config) {
    if (config.toUpperCase) {
      (event.target as HTMLInputElement).value = (event.target as HTMLInputElement).value.toUpperCase?.();
    }
  }
};

export const handleKeyPressNumber = (event: KeyboardEvent<HTMLInputElement>) => {
  const keyCode = event.keyCode || event.which;
  const keyValue = String.fromCharCode(keyCode);
  const pattern = /^[0-9\s]+$/;
  if (!pattern.test(keyValue)) {
    event.preventDefault();
  }
};

export const validateCitizenId = (citizenId?: string) => {
  if (!citizenId) return false;

  if (citizenId.length !== 13 || citizenId[0].match(/[09]/)) {
    return false;
  }

  const sum = citizenId
    .slice(0, 12)
    .split('')
    .reduce((acc, digit, index) => acc + parseInt(digit) * (13 - index), 0);

  return (11 - (sum % 11)) % 10 === parseInt(citizenId[12]);
};

export const validateThaiPhoneNumber = (phone: string): boolean => {
  const isValidLength = phone.length > 8 && phone.length < 11;
  const phoneNumberOfDigit = phone.split('');

  if (phoneNumberOfDigit.length === 9) {
    phoneNumberOfDigit.unshift('0');
  }

  const codeMobile = `${phoneNumberOfDigit[0]}${phoneNumberOfDigit[1]}`;

  const isMobilePhoneNumber = CODE_MOBILE_PHONE_THAILAND.some((code) => code === `${codeMobile}`);

  return isValidLength && isMobilePhoneNumber;
};

export const convertYearToGregorian = (year: number | string) => Number(year) - 543;
export const convertYearToBuddhist = (year: number | string) => Number(year) + 543;

export const withParenthesis = (value: string) => {
  if (value === '0') {
    return '';
  }
  return `(${value})`;
};

export const validateBirthDateBuddhist = (day: string, month: string, yearBuddhist: string) => {
  const year = convertYearToGregorian(yearBuddhist).toString();
  const birthDate = dayjs(`${day}/${month}/${year}`, 'DD/MM/YYYY');
  const birthDateWithFormat = birthDate.format('DD/MM/YYYY');
  const isSameDateAfterConvert =
    birthDate.format('DD') === day && birthDate.format('MM') === month && birthDate.format('YYYY') === year;
  const isValidDate = dayjs(birthDateWithFormat, 'DD/MM/YYYY', true).isValid() && isSameDateAfterConvert;
  const isPast = dayjs().isAfter(birthDate, 'day');

  return isValidDate && isPast;
};

export const formatName = (
  prefix?: string | null,
  firstname?: string | null,
  lastname?: string | null,
): string | undefined => {
  if (!prefix && !firstname && !lastname) {
    return;
  }
  return `${prefix || ''} ${firstname || ''} ${lastname || ''}`;
};

export const getImageCdnUrl = (path: string) => {
  if (path?.includes('http')) return path;
  return `${config.IMAGE_CDN_HOST}${path}`;
};

export const preventTextInputEventByRegex = (event: SyntheticEvent, pattern: RegExp, text: string) => {
  if (!pattern.test(text)) {
    event.preventDefault();
  }
};

export const isValidNumber = (input: string): boolean => {
  // Check if the input is a valid number and greater than 0
  return /^\d+$/.test(input) && Number(input) > 0;
};

export const delay = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const isObject = (value: any): value is Record<string, any> =>
  value && typeof value === 'object' && !Array.isArray(value);

export const toCamelCase = (data: any): any => {
  if (Array.isArray(data)) {
    return data.map(toCamelCase);
  } else if (isObject(data)) {
    return Object.keys(data).reduce((acc, key) => {
      const camelKey = camelCase(key);
      acc[camelKey] = toCamelCase(data[key]);
      return acc;
    }, {} as Record<string, any>);
  }
  return data;
};
